import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  CaretDown,
  CaretUpIcon,
  ConnectIcon,
  EnvelopEmail,
} from '../../../assets/images';
import {
  setGlossaryStateOpen,
  setGlossaryTag,
} from '../../../containers/DashBoard/actions';
import { checkUserEntitlements } from '../../../userConfig';
import { getCookie, setCookie } from '../../../utils/cookie-settings';
import { highlightSearchTerm } from '../../../utils/textRefactor';
import { updateTitle } from '../../../utils/title-util';
import { IconInfo } from '../../Icons';
import './styles.scss';
Moment.locale('en');

let selectedBattery;

const regEx = /^New_Product/;

const TitleBar = props => {
  const dispatch = useDispatch();
  const [expandOverAll, setExpandOverAll] = useState(
    getCookie('ess_preference') !== null &&
      getCookie('ess_preference') === 'true'
      ? getCookie('toggleOverAllInfo') !== 'false'
      : true
  );
  const openGlossary = () => {
    dispatch(setGlossaryStateOpen(true));
    dispatch(setGlossaryTag('Product Overview Meta data'));
  };
  useEffect(() => {
    setExpandOverAll(expandOverAll);
  }, [props.batteryOverallConditionData]);

  const searchParam = new URLSearchParams(window.location.search);

  useEffect(() => {
    updateTitle(window.location);
  }, [searchParam]);

  if (
    sessionStorage.getItem('selectedProductDname') !== null &&
    sessionStorage.getItem('selectedProductDname') !== 'null'
  ) {
    const str = sessionStorage.getItem('selectedProductDname');
    const arr = str.split('_');
    arr.pop();
    selectedBattery = str;
  }

  if (props.pageName === 'Fleetview') {
    const { searchQuery } = props;

    return (
      checkUserEntitlements('ESS:Fleet-Overallcondition') && (
        <React.Fragment>
          <Col data-intro="infobar" md={12} className="containertitlebar">
            <span className="text">
              {props.loadingOverallCondition === true ||
              props.overallConditionData === null
                ? 'Loading...'
                : props.overallConditionData[0].dnm}
              <ConnectIcon className="icontrain" />
            </span>

            <span>
              {checkUserEntitlements('ESS:common-Emailalertsetting') && (
                <button
                  data-intro="email-alert"
                  title="Email alert"
                  className="noStylebtn"
                  onClick={props.redirectToEmailAlert}>
                  <EnvelopEmail />
                </button>
              )}
              <button
                title="Switch between show/hide overall info"
                className="noStylebtn"
                onClick={() => {
                  setExpandOverAll(!expandOverAll);
                  // localStorage.setItem(
                  //   "toggleOverAllInfo",
                  //   JSON.stringify(!expandOverAll)
                  // );
                  if (
                    getCookie('ess_preference') !== null &&
                    getCookie('ess_preference') === 'true'
                  ) {
                    setCookie(
                      'toggleOverAllInfo=' + JSON.stringify(!expandOverAll)
                    );
                  }
                }}>
                {expandOverAll ? <CaretUpIcon /> : <CaretDown />}
              </button>
            </span>
          </Col>
          {expandOverAll && (
            <div className="fleetinfoborder">
              {props.loadingOverallCondition === true ||
              props.overallConditionData === null ? (
                'Loading...'
              ) : (
                <React.Fragment>
                  <Row>
                    <Col className="noLeftpadding">
                      <span className="mediumText">
                        {props.overallConditionData[0].vc}
                      </span>
                      <span className="fleetlistvalues">
                        {props.overallConditionData[0].vc > 1
                          ? 'Vehicles '
                          : 'Vehicle '}
                        in total
                      </span>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">Maintenance site:</span>
                      <span className="fleetlistvalues">
                        {highlightSearchTerm(
                          props.overallConditionData[0].dst,
                          searchQuery
                        )}
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">Operator:</span>
                      <span className="fleetlistvalues">
                        {highlightSearchTerm(
                          props.overallConditionData[0].opt,
                          searchQuery
                        )}
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">In operation since:</span>
                      <span className="fleetlistvalues">
                        {props.overallConditionData[0].nm ===
                        'SWISSTROLLEY_FRIBOURG'
                          ? highlightSearchTerm('05.05.2021', searchQuery)
                          : props.overallConditionData[0].nm === 'GTW_ARRIVA'
                            ? highlightSearchTerm('30.01.2020', searchQuery)
                            : highlightSearchTerm('15.04.2021', searchQuery)}
                      </span>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </div>
          )}
        </React.Fragment>
      )
    );
  } else if (props.pageName === 'Vehicleview') {
    const { searchQuery } = props;
    return (
      checkUserEntitlements('ESS:Vehicle-Overallcondition') && (
        <React.Fragment>
          <Col md={12} className="containertitlebar">
            <span className="text">
              {sessionStorage.getItem('selectedProductDname') !== 'null'
                ? sessionStorage.getItem('selectedProductDname')
                : sessionStorage.getItem('selectedVehicle') === null
                  ? ''
                  : sessionStorage.getItem('selectedVehicle')}
              <ConnectIcon className="icontrain" />
            </span>
            <span>
              {/* {checkUserEntitlements('ESS:Vehicle-Report') && (
                <img src={reportIcon} className="iconreport" title="Report" />
              )} */}
              {checkUserEntitlements('ESS:common-Emailalertsetting') && (
                <button
                  data-intro="email-alert"
                  title="Email alert"
                  className="noStylebtn"
                  onClick={props.redirectToEmailAlert}>
                  <EnvelopEmail />
                </button>
              )}
              <button
                title="Switch between show/hide overall info"
                className="noStylebtn"
                onClick={() => {
                  setExpandOverAll(!expandOverAll);
                  if (
                    getCookie('ess_preference') !== null &&
                    getCookie('ess_preference') === 'true'
                  ) {
                    setCookie(
                      'toggleOverAllInfo=' + JSON.stringify(!expandOverAll)
                    );
                  }
                }}>
                {expandOverAll ? <CaretUpIcon /> : <CaretDown />}
              </button>
            </span>
          </Col>
          {expandOverAll && (
            <div className="fleetinfoborder">
              {props.loadingVehicleDetails === true ||
              props.vehicleDetails === null ? (
                'Loading...'
              ) : (
                <>
                  <Row style={{ display: 'flow' }}>
                    <span className={'mediumText'}>Vehicle no.:</span>
                    <span className={'sub'}>
                      {sessionStorage.getItem('selectedVehicle') === null
                        ? ''
                        : highlightSearchTerm(
                            sessionStorage
                              .getItem('selectedVehicle')
                              .split('_')[
                              sessionStorage
                                .getItem('selectedVehicle')
                                .split('_').length - 1
                            ],
                            searchQuery
                          )}
                    </span>
                  </Row>
                  <Row className="">
                    <Col xs={12} sm={4} md={4} className="noLeftpadding">
                      <span className="mediumText">First data received:</span>
                      <span className="fleetlistvalues">
                        {highlightSearchTerm(
                          props.vehicleDetails.vfr,
                          searchQuery
                        )}
                      </span>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          )}
        </React.Fragment>
      )
    );
  } else if (
    props.pageName === 'Vehicleproductview' &&
    checkUserEntitlements('ESS:Product-Overallcondition')
  ) {
    const { searchQuery } = props;
    return (
      checkUserEntitlements('ESS:Product-Overallcondition') && (
        <React.Fragment>
          <Col md={12} className="containertitlebar">
            <span className="text">
              {props.loadingBatteryOverallCondition === true ||
              props.batteryOverallConditionData === null
                ? 'Loading...'
                : sessionStorage.getItem('selectedFleet') ===
                      'ABB ESS Demo Fleet' ||
                    regEx.test(sessionStorage.getItem('selectedProductDname'))
                  ? selectedBattery
                  : selectedBattery}
              <ConnectIcon className="icontrain" />
            </span>
            <span>
              {/* {checkUserEntitlements('ESS:Product-Report') && (
                <img src={reportIcon} className="iconreport" title="Report" />
              )} */}

              {checkUserEntitlements('ESS:common-Emailalertsetting') && (
                <button
                  data-intro="email-alert"
                  title="Email alert"
                  className="noStylebtn"
                  onClick={props.redirectToEmailAlert}>
                  <EnvelopEmail />
                </button>
              )}
              <button
                title="Switch between show/hide overall info"
                className="noStylebtn"
                onClick={() => {
                  setExpandOverAll(!expandOverAll);
                  if (
                    getCookie('ess_preference') !== null &&
                    getCookie('ess_preference') === 'true'
                  ) {
                    setCookie(
                      'toggleOverAllInfo=' + JSON.stringify(!expandOverAll)
                    );
                  }
                }}>
                {expandOverAll ? <CaretUpIcon /> : <CaretDown />}
              </button>
            </span>
          </Col>
          {expandOverAll && (
            <div className="fleetinfoborder">
              {props.loadingBatteryOverallCondition === true ||
              props.batteryOverallConditionData === null ? (
                'Loading...'
              ) : (
                <>
                  <Row>
                    <span className={'sub'}>
                      (Data updated on{' '}
                      {Moment(props.batteryOverallConditionData[0].ts).format(
                        'HH:mm \xa0DD.MM.YYYY'
                      )}
                      ){props.batteryOverallConditionData[0].tz}
                    </span>
                  </Row>
                  <Row className="">
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">ESS type:</span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm('ESS_HP_2233', searchQuery)
                          : highlightSearchTerm(
                              props.batteryOverallConditionData[0].typ,
                              searchQuery
                            )}
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">Rated capacity:</span>
                      <span className="fleetlistvalues">
                        {props.batteryOverallConditionData[0].rc} Ah
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">PEC article no.:</span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm('3BHE004455R0001', searchQuery)
                          : highlightSearchTerm(
                              props.batteryOverallConditionData[0].pa,
                              searchQuery
                            )}
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">
                        {sessionStorage.getItem('selectedFleet') ===
                        'SWISSTROLLEY_FRIBOURG'
                          ? 'Vehicle info no.:'
                          : 'PEC IP address:'}
                      </span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm('10.1.1.15', searchQuery)
                          : sessionStorage.getItem('selectedProductIP') !==
                                null &&
                              sessionStorage.getItem('selectedProductDname') !==
                                null &&
                              sessionStorage
                                .getItem('selectedProductDname')
                                .includes('New_Product') === true
                            ? highlightSearchTerm(
                                sessionStorage.getItem('selectedProductIP'),
                                searchQuery
                              )
                            : highlightSearchTerm(
                                props.batteryOverallConditionData[0].ip,
                                searchQuery
                              )}
                      </span>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">ESS article:</span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm(
                              props.batteryOverallConditionData[0].bpa,
                              searchQuery
                            )
                          : highlightSearchTerm(
                              props.batteryOverallConditionData[0].bpa,
                              searchQuery
                            )}
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">Rated voltage:</span>
                      <span className="fleetlistvalues">
                        {props.batteryOverallConditionData[0].rv} V
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">PEC serial no.:</span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm(
                              props.batteryOverallConditionData[0].bps,
                              searchQuery
                            )
                          : sessionStorage.getItem('selectedFleet') ===
                              'SWISSTROLLEY_FRIBOURG'
                            ? highlightSearchTerm(
                                'C' + props.batteryOverallConditionData[0].ps,
                                searchQuery
                              )
                            : highlightSearchTerm(
                                props.batteryOverallConditionData[0].ps,
                                searchQuery
                              )}
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">RCM library version:</span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm('1.2.2.0', searchQuery)
                          : highlightSearchTerm(
                              props.batteryOverallConditionData[0].rcm,
                              searchQuery
                            )}
                      </span>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">ESS serial no.:</span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm(
                              props.batteryOverallConditionData[0].bps,
                              searchQuery
                            )
                          : highlightSearchTerm(
                              props.batteryOverallConditionData[0].bps,
                              searchQuery
                            )}
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">Rated energy:</span>
                      <span className="fleetlistvalues">
                        {props.batteryOverallConditionData[0].rp} kWh
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">PEC software version:</span>
                      <span className="fleetlistvalues">
                        {props.batteryOverallConditionData[0].swv !==
                        '0.0.0.0' ? (
                          sessionStorage.getItem('selectedFleet') ===
                          'ABB ESS Demo Fleet' ? (
                            highlightSearchTerm('1.1.3.0', searchQuery)
                          ) : (
                            highlightSearchTerm(
                              props.batteryOverallConditionData[0].swv,
                              searchQuery
                            )
                          )
                        ) : (
                          <>
                            {highlightSearchTerm(
                              props.batteryOverallConditionData[0].swv,
                              searchQuery
                            )}
                            <span className="mockmsg">(mock data)</span>
                          </>
                        )}
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <button
                        data-intro="glossary-icon"
                        title="Glossary Icon"
                        className="noStylebtn"
                        onClick={openGlossary}>
                        <IconInfo color={'black'} />
                      </button>
                    </Col>
                  </Row>
                </>
              )}{' '}
            </div>
          )}
        </React.Fragment>
      )
    );
  } else if (
    props.pageName === 'TractionConverterView' &&
    checkUserEntitlements('ESS:Product-Overallcondition')
  ) {
    const { searchQuery } = props;
    return (
      checkUserEntitlements('ESS:Product-Overallcondition') && (
        <React.Fragment>
          <Col md={12} className="containertitlebar">
            <span className="text">
              {props.loadingBatteryOverallCondition === true ||
              props.batteryOverallConditionData === null
                ? 'Loading...'
                : sessionStorage.getItem('selectedFleet') ===
                      'ABB ESS Demo Fleet' ||
                    regEx.test(sessionStorage.getItem('selectedProductDname'))
                  ? selectedBattery
                  : selectedBattery}
              <ConnectIcon className="icontrain" />
            </span>
            <span>
              {/* {checkUserEntitlements('ESS:Product-Report') && (
                <img src={reportIcon} className="iconreport" title="Report" />
              )} */}
              {checkUserEntitlements('ESS:common-Emailalertsetting') && (
                <button
                  data-intro="email-alert"
                  title="Email alert"
                  className="noStylebtn"
                  onClick={props.redirectToEmailAlert}>
                  <EnvelopEmail />
                </button>
              )}
              <button
                title="Switch between show/hide overall info"
                className="noStylebtn"
                onClick={() => {
                  setExpandOverAll(!expandOverAll);
                  if (
                    getCookie('ess_preference') !== null &&
                    getCookie('ess_preference') === 'true'
                  ) {
                    setCookie(
                      'toggleOverAllInfo=' + JSON.stringify(!expandOverAll)
                    );
                  }
                }}>
                {expandOverAll ? <CaretUpIcon /> : <CaretDown />}
              </button>
            </span>
          </Col>
          {expandOverAll && (
            <div className="fleetinfoborder">
              {props.loadingBatteryOverallCondition === true ||
              props.batteryOverallConditionData === null ? (
                'Loading...'
              ) : (
                <>
                  <Row>
                    <span className={'sub'}>
                      (Data updated on{' '}
                      {Moment(props.batteryOverallConditionData[0].ts).format(
                        'HH:mm \xa0DD.MM.YYYY'
                      )}
                      ){props.batteryOverallConditionData[0].tz}
                    </span>
                  </Row>
                  <Row className="">
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">
                        {sessionStorage.getItem('selectedFleet') ===
                        'SWISSTROLLEY_FRIBOURG'
                          ? 'Vehicle info no.:'
                          : 'PEC IP address:'}
                      </span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm('10.1.1.15', searchQuery)
                          : sessionStorage.getItem('selectedProductIP') !==
                                null &&
                              sessionStorage.getItem('selectedProductDname') !==
                                null &&
                              sessionStorage
                                .getItem('selectedProductDname')
                                .includes('New_Product') === true
                            ? highlightSearchTerm(
                                sessionStorage.getItem('selectedProductIP'),
                                searchQuery
                              )
                            : highlightSearchTerm(
                                props.batteryOverallConditionData[0].ip,
                                searchQuery
                              )}
                      </span>
                    </Col>
                    <Col xs={12} sm={3} md={3} className="noLeftpadding">
                      <span className="mediumText">PEC software version:</span>
                      <span className="fleetlistvalues">
                        {props.batteryOverallConditionData[0].swv !==
                        '0.0.0.0' ? (
                          sessionStorage.getItem('selectedFleet') ===
                          'ABB ESS Demo Fleet' ? (
                            highlightSearchTerm('1.1.3.0', searchQuery)
                          ) : (
                            highlightSearchTerm(
                              props.batteryOverallConditionData[0].swv,
                              searchQuery
                            )
                          )
                        ) : (
                          <>
                            {highlightSearchTerm(
                              props.batteryOverallConditionData[0].swv,
                              searchQuery
                            )}
                            <span className="mockmsg">(mock data)</span>
                          </>
                        )}
                      </span>
                    </Col>
                  </Row>
                </>
              )}{' '}
            </div>
          )}
        </React.Fragment>
      )
    );
  } else if (
    props.pageName === 'Packview' &&
    checkUserEntitlements('ESS:Product-Overallcondition')
  ) {
    const { searchQuery } = props;
    return (
      checkUserEntitlements('ESS:Product-Overallcondition') && (
        <React.Fragment>
          <Col md={12} className="containertitlebar">
            <span className="text">
              {props.loadingBatteryOverallCondition === true ||
              props.batteryOverallConditionData === null
                ? 'Loading...'
                : sessionStorage.getItem('selectedFleet') ===
                      'ABB ESS Demo Fleet' ||
                    regEx.test(sessionStorage.getItem('selectedPackDname'))
                  ? sessionStorage.getItem('selectedPackDname')
                  : sessionStorage.getItem('selectedPackDname')}
              <ConnectIcon />
            </span>

            <a
              title="Switch between show/hide overall info"
              className="iconCollapse"
              onClick={() => {
                setExpandOverAll(!expandOverAll);
                if (
                  getCookie('ess_preference') !== null &&
                  getCookie('ess_preference') === 'true'
                ) {
                  setCookie(
                    'toggleOverAllInfo=' + JSON.stringify(!expandOverAll)
                  );
                }
              }}>
              {expandOverAll ? <CaretUpIcon /> : <CaretDown />}
            </a>
            {/* {checkUserEntitlements('ESS:Product-Report') && (
              <img src={reportIcon} className="iconreport" title="Report" />
            )} */}
            {checkUserEntitlements('ESS:common-Emailalertsetting') && (
              <button
                data-intro="email-alert"
                title="Email alert"
                className="noStylebtn"
                onClick={props.redirectToEmailAlert}>
                <EnvelopEmail />
              </button>
            )}
          </Col>
          {expandOverAll && (
            <div className="fleetinfoborder">
              {props.loadingBatteryOverallCondition === true ||
              props.batteryOverallConditionData === null ? (
                'Loading...'
              ) : (
                <>
                  <Row>
                    <span className={'sub'}>
                      (Data updated on{' '}
                      {Moment(props.batteryOverallConditionData[0].ts).format(
                        'HH:mm \xa0DD.MM.YYYY'
                      )}
                      ){props.batteryOverallConditionData[0].tz}
                    </span>
                  </Row>
                  <Row className="">
                    <Col xs={12} sm={4} md={4} className="noLeftpadding">
                      <span className="mediumText">ESS type:</span>
                      <span className="fleetlistvalues">
                        {highlightSearchTerm('ESS_R_007A01', searchQuery)}
                      </span>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="noLeftpadding">
                      <span className="mediumText">Rated capacity:</span>
                      <span className="fleetlistvalues">
                        {props.batteryOverallConditionData[0].rc} Ah
                      </span>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="noLeftpadding">
                      <span className="mediumText">SCU serial no.:</span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm('3BHE004455R0001', searchQuery)
                          : highlightSearchTerm(
                              props.batteryOverallConditionData[0].scu,
                              searchQuery
                            )}
                      </span>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={12} sm={4} md={4} className="noLeftpadding">
                      <span className="mediumText">ESS article:</span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm(
                              props.batteryOverallConditionData[0].bpa,
                              searchQuery
                            )
                          : highlightSearchTerm(
                              props.batteryOverallConditionData[0].bpa,
                              searchQuery
                            )}
                      </span>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="noLeftpadding">
                      <span className="mediumText">Rated voltage:</span>
                      <span className="fleetlistvalues">
                        {props.batteryOverallConditionData[0].rv} V
                      </span>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="noLeftpadding">
                      <span className="mediumText">PEC software version:</span>
                      <span className="fleetlistvalues">
                        {props.batteryOverallConditionData[0].swv !==
                        '0.0.0.0' ? (
                          sessionStorage.getItem('selectedFleet') ===
                          'ABB ESS Demo Fleet' ? (
                            highlightSearchTerm('1.1.3.0', searchQuery)
                          ) : (
                            highlightSearchTerm(
                              props.batteryOverallConditionData[0].swv,
                              searchQuery
                            )
                          )
                        ) : (
                          <>
                            {highlightSearchTerm(
                              props.batteryOverallConditionData[0].swv,
                              searchQuery
                            )}
                            <span className="mockmsg">(mock data)</span>
                          </>
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col xs={12} sm={4} md={4} className="noLeftpadding">
                      <span className="mediumText">ESS serial no.:</span>
                      <span className="fleetlistvalues">
                        {sessionStorage.getItem('selectedFleet') ===
                        'ABB ESS Demo Fleet'
                          ? highlightSearchTerm(
                              props.batteryOverallConditionData[0].bps,
                              searchQuery
                            )
                          : highlightSearchTerm(
                              props.batteryOverallConditionData[0].bps,
                              searchQuery
                            )}
                      </span>
                    </Col>
                    <Col xs={12} sm={4} md={4} className="noLeftpadding">
                      <span className="mediumText">Rated energy:</span>
                      <span className="fleetlistvalues">
                        {props.batteryOverallConditionData[0].rp} kWh
                      </span>
                    </Col>

                    <Col xs={12} sm={4} md={4} className="noLeftpadding"></Col>
                  </Row>
                </>
              )}{' '}
            </div>
          )}
        </React.Fragment>
      )
    );
  } else {
    return null;
  }
};

export default TitleBar;
