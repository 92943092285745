import React from 'react';
import { Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loading from '../../components/Loading';
import {
  setGlossaryStateOpen,
  setGlossaryTag,
} from '../../containers/DashBoard/actions';
import { checkUserEntitlements } from '../../userConfig';
import { platformVersion } from '../../utils/excel-utils';
import {
  extractData,
  extractSeriesData,
  findMinHeights,
} from '../../utils/fleetStatisticsDataUtil';
import {
  createUrlFromParams,
  findVehicleFromEssHandler,
  getMonthYearFormatted,
} from '../../utils/heatmapblockclick-util';
import Diagnostic from '../Diagnostic';
import EventList from '../Events';
import { FleetStatModalContent } from '../FleetStatModalContent';
import Modal from '../FleetStatsModal/Modal';
import FleetUsage from '../FleetUsage/FleetUsage';
import { Heatmap } from '../HeatMapStats';
import { IconInfo } from '../Icons';
import Overview from '../Overview';
import './styles.scss';

class FleetTabs extends React.Component {
  constructor(props) {
    super(props);
    const currentYear = new Date().getFullYear();
    const options = [{ label: 'Last 12 months', value: '' }];
    for (let i = currentYear; i >= currentYear - 4; i--) {
      options.push({ label: i.toString(), value: i });
    }
    this.state = {
      updatedVehiclesListData: this.props.vehiclesListData,
      options,
      fleetStatisticsData: null,
      viewWidth: 0,
      isResizing: this.props.isResizing,
      showSidebar: this.props.showSidebar,
      loader: false,
      names: [],
      showOverlay: false,
      popupSelectedOption: '',
      startDate: '',
      endDate: '',
      monthDay: '',
      vehicleName: '',
      platformNumber: null,
      isFixed: false,
      optiontoSelect: [],
    };
    this.devRef = React.createRef();
  }

  handleScroll = () => {
    const isFixed = window.scrollY >= 120;
    if (isFixed !== this.state.isFixed) {
      this.setState({ isFixed });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const searchParams = new URLSearchParams(window.location.search);
    const prevSearchParams = new URLSearchParams(prevProps.location.search);
    if (
      this.props.fleetStatsDataLoader === false &&
      this.state.fleetStatisticsData !== this.props.fleetStatisticsData
    ) {
      this.setState({ fleetStatisticsData: this.props.fleetStatisticsData });
    }
    if (
      prevSearchParams.get('ft') === searchParams.get('ft') &&
      prevSearchParams.get('tb') !== searchParams.get('tb')
    ) {
      this.props.setAllEventListData({
        pageSize: 20,
        pageIndex: 0,
        uniqueKey: 'All',
        uniqueValue: 'All',
        sortValue: true,
        sortValueKey: 'dt',
      });
    }
    if (
      prevProps.isResizing !== this.props.isResizing ||
      prevProps.showSidebar !== this.props.showSidebar
    ) {
      this.setState({ loader: true });

      setTimeout(() => {
        this.setState({ loader: false });
      }, 1000);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps, state) {
    if (platformVersion() !== newProps.platformNumber) {
      return { state, platformNumber: newProps.platformNumber };
    }
  }

  componentWillUnmount() {
    sessionStorage.removeItem('selectedOption');
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleCloseOverlay = () => {
    this.setState({ showOverlay: false });
    this.setState({ names: [] });
  };

  openGlossary = () => {
    this.props.setGlossaryStateOpen(true);
    this.props.setGlossaryTag('Throughput view');
  };

  handleSubmitOverlayOption = option => {
    this.setState({ popupSelectedOption: option }, () =>
      this.redirectToPowerHandler(this.state.startDate, this.state.endDate)
    );
    this.setState({ showOverlay: false });
  };

  getLatestEssValue = name => {
    const { startDate, endDate } = this.state;
    if (platformVersion() === '3') {
      this.setState({ showOverlay: true });
    } else if (name.includes(',')) {
      const values = name.split(',');
      this.setState({ names: values });
      if (this.state.names.length > 1) {
        this.setState({ showOverlay: true });
      }
    } else {
      this.setState({ popupSelectedOption: name }, () =>
        this.redirectToPowerHandler(startDate, endDate)
      );
    }
  };

  redirectToPowerHandler = (startDate, endDate) => {
    const { vehiclesListData, unmountedESSListData } = this.props;
    const { popupSelectedOption, optiontoSelect } = this.state;
    if (popupSelectedOption !== null || popupSelectedOption !== 'undefined') {
      const { vn, dn, bpu, an, ip } = findVehicleFromEssHandler(
        platformVersion() === '3' ? optiontoSelect.name : popupSelectedOption,
        vehiclesListData,
        unmountedESSListData
      );
      const params = {
        st: sessionStorage.getItem('selectedSite'),
        ft: sessionStorage.getItem('selectedFleet'),
        fty: 'BESS',
        vc: vn,
        vp: an,
        pip: ip,
        pdn: dn,
        sdt: this.state.startDate,
        edt: this.state.endDate,
        unmas: sessionStorage.getItem('unmountedESSclicked'),
        ftr: '%2C%2C',
        srch: '',
        bpu,
        tto: 'Power',
        tb: 'Usage',
        utb: 'usgpow',
        cdty: 'Custom',
      };
      const vehicleSelected = vn;
      if (vehicleSelected === undefined) return;
      // collect all params here
      const newURL = createUrlFromParams(
        platformVersion() !== '3'
          ? params
          : {
              ...params,
              pk: popupSelectedOption.scu || '',
              pkdn: popupSelectedOption.nm || '',
              scuID: popupSelectedOption.scuID || '',
            }
      );
      const url = new URL(newURL);
      // next a function will run that will create the url

      this.setState({ popupSelectedOption: '', optiontoSelect: [] });

      this.props.history.push(url.pathname + url.search);
      // push the history ti the new generated url
    }
  };

  onHeatmapBlockClickHandler = async (event, data) => {
    if (
      event.name === null ||
      event.name === 'null' ||
      (platformVersion() !== '3' &&
        !checkUserEntitlements('ESS:Product-Usage')) ||
      (platformVersion() !== '3' &&
        !checkUserEntitlements('ESS:Product-Usage-Power'))
    ) {
      return false;
    }
    const date = event.series.xAxis.categoriesTree[event.x];
    this.setState({ monthDay: date, vehicleName: event.series.name });
    const { startDate, endDate } = getMonthYearFormatted(date);
    this.setState({ startDate, endDate, optiontoSelect: data }, () =>
      this.getLatestEssValue(event.name)
    );
  };

  render() {
    const { isFixed } = this.state;
    return (
      <>
        {this.state.showOverlay && (
          <Modal onClose={this.handleCloseOverlay}>
            <FleetStatModalContent
              options={
                platformVersion() !== '3'
                  ? this.state.names
                  : this.state.optiontoSelect
              }
              onSubmit={
                (platformVersion() === '3' &&
                  !checkUserEntitlements('ESS:Product-Usage')) ||
                (platformVersion() === '3' &&
                  !checkUserEntitlements('ESS:Product-Usage-Power'))
                  ? () => {}
                  : this.handleSubmitOverlayOption
              }
              heading={`Swap at - ${this.state.vehicleName} - ${this.state.monthDay}`}
              vehicleName={this.state.vehicleName}
              date={this.state.monthDay}
            />
          </Modal>
        )}
        <div className="tabfleetview">
          <Tabs
            id="fleet"
            style={{ width: this.props.heatmap_min_width - 5 }}
            className={`main-tab-content${isFixed ? ' fixed' : ''}`}
            activeKey={this.props.selectedTab}
            onSelect={k => this.props.onFleetViewTabClick(k)}>
            {checkUserEntitlements('ESS:Fleet-Overview', 'Overview') && (
              <Tab
                eventKey="Overview"
                data-intro="overview"
                title={<span className="tabtitle"> Overview</span>}>
                {this.props.loadingVehicles === true ||
                this.props.vehiclesListData === null ? (
                  <Loading loadintText="Loading data" />
                ) : this.props.vehiclesListData &&
                  this.props.vehiclesListData[0].vst.length > 0 &&
                  this.props.vehiclesListData &&
                  this.props.vehiclesListData[0].data.length > 0 ? (
                  <Overview
                    overviewData={this.props.overviewData}
                    onVehicleListClick={this.props.onVehicleListClick}
                    vehiclesListData={this.props.vehiclesListData}
                    listSelection={this.props.listSelection}
                    filterItems={this.props.filterItems}
                    userConfig={this.props.userConfig}
                    sideMenuFilter={this.props.sideMenuFilter}
                    filterAvailability={this.props.filterAvailability}
                    filterReliability={this.props.filterReliability}
                    filterUtilization={this.props.filterUtilization}
                  />
                ) : (
                  <Col md={12} className="addMarginTop nodatafound">
                    <div> Sorry...</div>
                    <div className="addMarginTop">No data found !</div>
                  </Col>
                )}
              </Tab>
            )}
            {/* <Tab
            eventKey="Energy-throughput"
            title={<span className="tabtitle"> Energy throughput</span>}
            disabled
          > */}
            {/* <Tabs id="throughput">
              <Tab
                eventKey="boxplot"
                title={<span className="tabtitle"> Box plot</span>}
              >
                {this.props.loadingBoxPlot !== true &&
                this.props.boxPlotData !== null ? (
                  <SingleGraph
                    chartType="BOX_TYPE"
                    data={this.props.boxPlotData}
                  />
                ) : (
                  <Loading loadintText="Loading data" />
                )}
              </Tab>
              <Tab
                eventKey="values"
                title={<span className="tabtitle"> Values</span>}
              >
                <Accordions />
              </Tab>
            </Tabs> */}
            {/* {this.props.loadingBoxPlot === true ||
            this.props.boxPlotData === null ? (
              <Loading loadintText="Loading data" />
            ) : this.props.boxPlotData.length > 0 ? (
              <>
                {" "}
                <span className="mockmsg">
                  (The data shown in this section is mock data)
                </span>
                <SingleGraph
                  chartType="BOX_TYPE"
                  data={this.props.boxPlotData}
                />
              </>
            ) : (
              <Col md={12} className="addMarginTop nodatafound">
                <div> Sorry...</div>
                <div className="addMarginTop">No data found !</div>
              </Col>
            )}
          </Tab> */}
            {/* <Tab
            eventKey="SoC-range-usage"
            title={<span className="tabtitle"> SoC Range usage</span>}
            disabled
          >
            {this.props.loadingHistogram !== true &&
            this.props.histogramData !== null ? (
              <>
                <SoCTable pageName="fleetSOCRange" />
                <SingleGraph
                  chartType="HISTOGRAM_TYPE"
                  data={this.props.histogramData}
                />
              </>
            ) : (
              <Loading loadintText="Loading data" />
            )}
          </Tab> */}
            {/** Fleet statistics troughput heatmap */}
            {checkUserEntitlements('ESS:Throughput') && (
              <Tab
                eventKey="fleet-throughput"
                title={<span className="tabtitle">Throughput</span>}>
                <div ref={this.devRef} id="fleet-fleetstatistics">
                  <button
                    data-intro="glossary-icon"
                    title="Glossary Icon"
                    className="infoIcon"
                    onClick={this.openGlossary}>
                    <IconInfo color={'black'} />
                  </button>
                  <div className="outer-wrapper">
                    <div className="fleetStatTitle">Energy throughput</div>
                    <div className="select-options">
                      <Form.Group
                        as={Col}
                        md={12}
                        controlId="formGroupSelect"
                        style={{ margin: '0px' }}>
                        <Form.Select
                          className="fleetStat-select"
                          onChange={this.props.handleOptionChange}
                          value={this.props.selectedOption}>
                          {this.state.options.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </div>
                  {this.props.fleetStatsDataLoader === true ||
                  this.state.loader ? (
                    <Loading loadintText="Loading ..." />
                  ) : this.props.fleetStatisticsData.info &&
                    this.props.fleetStatisticsData.info.length !== 0 ? (
                    <div className="heatmap-wrapper">
                      <Heatmap
                        SERIES_DATA={
                          this.props.fleetStatisticsData
                            ? extractSeriesData(this.props.fleetStatisticsData)
                            : []
                        }
                        STORE_CATEGORIES={
                          this.props.fleetStatisticsData
                            ? extractData(this.props.fleetStatisticsData)
                            : []
                        }
                        X_AXIS_CATEGORIES={
                          this.props.fleetStatisticsData
                            ? this.props.fleetStatisticsData.xaxis
                            : []
                        }
                        primary={true}
                        max={parseInt(
                          this.props.fleetStatisticsData.config.htp
                        )}
                        midPoint={parseInt(
                          this.props.fleetStatisticsData.config.dtp
                        )}
                        fStart={this.props.fleetStatisticsData.config.fsc}
                        fEnd={this.props.fleetStatisticsData.config.fec}
                        sStart={this.props.fleetStatisticsData.config.ssc}
                        sEnd={this.props.fleetStatisticsData.config.sec}
                        heatmap_min_height={
                          sessionStorage.getItem('selectedFleet') !==
                          'ABB ESS Demo Fleet'
                            ? findMinHeights(this.props.fleetStatisticsData)
                            : 500
                        }
                        heatmap_min_width={
                          this.props.heatmap_min_width &&
                          this.props.heatmap_min_width - 12
                        }
                        onBlockClick={this.onHeatmapBlockClickHandler}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'block',
                        textAlign: 'center',
                        margin: '0 auto',
                      }}>
                      <div className="addMarginTop nodatafound col-md-12">
                        <div> Sorry...</div>
                        <div className="addMarginTop">No data found !</div>
                      </div>
                    </div>
                  )}
                </div>
              </Tab>
            )}
            {checkUserEntitlements('ESS:Statistics') && (
              <Tab
                eventKey="fleet-statistics"
                title={
                  <span className="tabtitle" data-intro="fleet-satistics">
                    Statistics
                  </span>
                }>
                <div
                  className="custom-tab-content"
                  id="fleet-statistics"
                  style={{
                    // position: "absolute",
                    width: this.props.heatmap_min_width - 5,
                  }}>
                  <FleetUsage
                    vehicleList={this.props.vehiclesListData}
                    getFleetUsageDetails={this.props.getFleetUsageDetails}
                    fleetUsageDetails={this.props.fleetUsageDetails}
                    loadingFleetUsageSelector={
                      this.props.loadingFleetUsageSelector
                    }
                    loader={this.state.loader}
                  />
                </div>
              </Tab>
            )}

            {(sessionStorage.getItem('selectedFleet') === 'ABB ESS Demo Fleet'
              ? checkUserEntitlements('ESS:Demo-Events', 'fleet-eventlist')
              : checkUserEntitlements(
                  'ESS:Fleet-Events',
                  'fleet-eventlist'
                )) && (
              <Tab
                eventKey="fleet-eventlist"
                title={<span className="tabtitle">Events List</span>}>
                <div
                  className="custom-tab-content"
                  id="fleet-evnt-list"
                  style={{
                    // position: "absolute",
                    width: this.props.heatmap_min_width - 5,
                  }}>
                  <>
                    <EventList
                      eventListConfig={
                        this.props.eventListConfig && this.props.eventListConfig
                      }
                      eventData={this.props.eventData}
                      setAllEventListData={this.props.setAllEventListData}
                      fetchAllEventList={this.props.fetchAllEventList}
                      eventList={this.props.eventList && this.props.eventList}
                      handleEventInputChange={this.props.handleEventInputChange}
                      handleEventRadioChange={this.props.handleEventRadioChange}
                      setEventUpdateDate={this.props.setEventUpdateDate}
                      setEventNewPec={this.props.setEventNewPec}
                      eventFormValues={this.props.eventFormValues}
                      handleClose={this.props.handleClose}
                      handleEventSubmit={this.props.handleEventSubmit}
                      loadingSaveEvent={this.props.loadingSaveEvent}
                      saveEventResponce={this.props.saveEventResponce}
                      eventFilterApply={this.props.eventFilterApply}
                      loadingEventList={this.props.loadingEventList}
                      vehiclesListData={this.props.vehiclesListData}
                      selectedView={this.props.selectedView}
                      handleUndoSubmit={this.props.handleUndoSubmit}
                      loadingUndoEvent={this.props.loadingUndoEvent}
                      undoEventResponce={this.props.undoEventResponce}
                      userConfig={this.props.userConfig}
                      redirectToTimetdOnStreesEvtClk={
                        this.props.redirectToTimetdOnStreesEvtClk
                      }
                    />
                  </>
                </div>
              </Tab>
            )}

            {checkUserEntitlements(
              'ESS:Diagnostic-event-tab',
              'fleet-diagnostic'
            ) && (
              <Tab
                eventKey="fleet-diagnostic"
                title={<span className="tabtitle">Diagnostics</span>}>
                <div
                  className="custom-tab-content"
                  id="fleet-diag-list"
                  style={{
                    // position: "absolute",
                    width: this.props.heatmap_min_width - 5,
                  }}>
                  <Diagnostic
                    entitlements={this.props.userConfig.entitlements}
                    selectedView={this.props.selectedView}
                    vehiclesListData={this.props.vehiclesListData}
                    fetchDiagnosticList={this.props.fetchDiagnosticList}
                    loadingDiagnosticList={this.props.loadingDiagnosticList}
                    diagnosticList={
                      this.props.diagnosticList && this.props.diagnosticList
                    }
                    redirectToTimetrendFrmDiagnostic={
                      this.props.redirectToTimetrendFrmDiagnostic
                    }
                    copyLinkDiagnosticTab={this.props.copyLinkDiagnosticTab}
                    diagnosticListConfig={
                      this.props.diagnosticListConfig &&
                      this.props.diagnosticListConfig
                    }
                    diagnosticEventData={
                      this.props.diagnosticEventData &&
                      this.props.diagnosticEventData
                    }
                    setDiagnosticEventListData={
                      this.props.setDiagnosticEventListData
                    }
                    diagFilterApply={this.props.diagFilterApply}
                    platformNumber={this.state.platformNumber}
                  />
                </div>
              </Tab>
            )}
          </Tabs>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  setGlossaryStateOpen,
  setGlossaryTag,
};

export default withRouter(connect(null, mapDispatchToProps)(FleetTabs));
