import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import RenewToken from './RenewToken';
import VersionInfo from './components/Footer/VersionInfo';
import NotFound from './components/NotFound';
import Unauthorized from './components/Unauthorized/Unauthorized';
import Broadcast from './containers/Broadcast';
import BroadcastBanner from './containers/Broadcast/BroadcastBanner';
import BroadcastMesssageDetails from './containers/Broadcast/BroadcastMesssageDetails';
import { activeMessages } from './containers/Broadcast/selectors';
import Dashboard from './containers/DashBoard/';
import { setGlossaryTag } from './containers/DashBoard/actions';
import { setGlossaryStateOpenSaga } from './containers/DashBoard/sagas';
import {
  glossaryStateSelector,
  glossaryTagSelector,
} from './containers/DashBoard/selectors';
import EmailAlertView from './containers/EmailAlertView';
import NetworkError from './containers/ErrorPages/NetworkError';
import ServerError from './containers/ErrorPages/ServerError';
import FleetSetup from './containers/FleetSetup';
import FleetView from './containers/FleetView';
import Glossary from './containers/Glossary';
import HeaderPage from './containers/Header';
import PackView from './containers/PackView';
import ReportView from './containers/ReportView';
import SafeMode from './containers/SafeMode';
import TractionConverterView from './containers/TractionConverterView';
import VehicleProductsView from './containers/VehicleProductsView';
import VehicleView from './containers/VehicleView';
import history from './history';

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMsgDetails: false,
      showGlossary: false,
    };
  }

  handleClose = () => {
    this.setState({ showMsgDetails: false });
  };

  handleShow = () => {
    this.setState({ showMsgDetails: true });
  };

  openGlossary = () => {
    this.props.setGlossaryTag('');
    this.setState({ showGlossary: true });
  };

  closeGlossary = () => {
    this.setState({ showGlossary: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.glossaryState !== this.props.glossaryState) {
      this.setState({ showGlossary: this.props.glossaryState });
    }
  }

  render() {
    return (
      <Router history={history}>
        <Fragment>
          <header>
            <HeaderPage
              toggleGlossary={this.toggleGlossary}
              checkSafeModeStatus={this.props.checkSafeModeStatus}
              showMessages={this.handleShow}
              messageCount={
                this.props.activeMessages && this.props.activeMessages.length
              }
            />

            {this.props.activeMessages &&
              this.props.activeMessages.length > 0 && (
                <BroadcastBanner
                  activeMessages={this.props.activeMessages}
                  handleShow={this.handleShow}
                />
              )}
            <BroadcastMesssageDetails
              show={this.state.showMsgDetails}
              handleClose={this.handleClose}
              messages={this.props.activeMessages}
            />
          </header>
          <div style={{ position: 'fixed', zIndex: '999', right: '1px' }}>
            {!this.state.showGlossary && (
              <button
                onClick={() => this.openGlossary()}
                style={{
                  zIndex: '99',
                  top: '500px',
                  position: 'relative',
                  backgroundColor: 'white',
                  padding: '12px 4px 8px 4px',
                  fontWeight: 'bold',
                  borderRadius: '8px 0px 0px 8px',
                  boxShadow:
                    '0px 8px 16px 0px var(--Opacity-black-12, rgba(0, 0, 0, 0.12)), 0px 2px 4px 0px var(--Opacity-black-8, rgba(0, 0, 0, 0.08)), 0px 0px 1px 0px var(--Opacity-black-8, rgba(0, 0, 0, 0.08))',
                }}>
                <span style={{ writingMode: 'vertical-rl', rotate: '180deg' }}>
                  Glossary
                </span>
              </button>
            )}
            {this.state.showGlossary && (
              <div
                style={{
                  width: '500px',
                  backgroundColor: 'white',
                  height: '92vh',
                  overflowX: 'none',
                  overflowY: 'scroll',
                  boxShadow: '-4px 1px 8px 0px rgba(0, 0, 0, 0.15)',
                  borderRadius: '0px 0px 0px 0px',
                  animation: '1s ease-out 0s 1 slideInFromLeft',
                }}>
                <Glossary
                  toggleGlossary={this.closeGlossary}
                  tagDetails={this.props.glossaryTag}
                />
              </div>
            )}
          </div>
          <Switch>
            <Route
              exact
              path="/Dashboard"
              component={Dashboard}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView"
              component={FleetView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView/VehicleView"
              component={VehicleView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView/VehicleView/VehicleProductsView"
              component={VehicleProductsView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView/VehicleView/TractionConverterView"
              component={TractionConverterView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView/VehicleView/VehicleProductsView/PackView"
              component={PackView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/Dashboard/FleetConfiguration"
              component={FleetSetup}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView/EmailAlertView"
              component={EmailAlertView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/Dashboard/ReportView"
              component={ReportView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/Dashboard/SafeMode"
              component={SafeMode}
              isAuthenticated={true}
            />
            <Route
              exact
              path="/Dashboard/Broadcast"
              component={Broadcast}
              isAuthenticated={true}
            />
            <Route
              exact
              path="/access-denied"
              component={Unauthorized}
              isAuthenticated={true}
            />
            {/* <Route
              exact
              path="/helpdoc/"
              component={Help}
              isAuthenticated={true}
            /> */}
            <Route
              exact
              path="/Glossary/:letter?"
              component={Glossary}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/RenewToken"
              component={RenewToken}
              isAuthenticated={true}
            />

            <Route exact path="/networkError" component={NetworkError} />
            <Route exact path="/serverError" component={ServerError} />
            <Route exact path="/" component={Dashboard} />
            {/* <PrivateRoute
              path="/"
              component={Dashboard}
              isAuthenticated={true}
            /> */}
            <Route path="**" component={NotFound} />
          </Switch>
          <footer className="footer">
            <VersionInfo />
          </footer>
        </Fragment>
      </Router>
    );
  }
}

// export
const mapStateToProps = createStructuredSelector({
  activeMessages: activeMessages(),
  glossaryState: glossaryStateSelector(),
  glossaryTag: glossaryTagSelector(),
});
const mapDispatchToProps = {
  setGlossaryTag,
  setGlossaryStateOpenSaga,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);

// export default Routes;
