import React, { useEffect, useRef, useState } from 'react';
import { MonthPicker } from './MonthPicker';
import { YearPicker } from './YearPicker';
import { months } from './constants';

function CustomPicker({ pickerValue, setpickerValue, disabled }) {
  const pickerRef = useRef(null);
  const pickerInputRef = useRef(null);
  const [toggle, settoggle] = useState(false);
  const [subtoggle, setsubtoggle] = useState('year');
  const [selectedYear, setselectedYear] = useState(new Date().getFullYear());

  const [selectedMonthYear, setselectedMonthYear] = useState([
    {
      year: new Date().getFullYear(),
      month: months[0],
    },
    {
      year: new Date().getFullYear(),
      month: months[new Date().getMonth()],
    },
  ]);

  const styles = {
    input: {
      width: '100%',
      height: '38px',
      lineHeight: '38px',
      fontSize: '16px',
      border: '1px solid #0000001F',
      textAlign: 'center',
      caretColor: 'transparent',
      cursor: 'pointer',
    },
    inputDisabled: {
      width: '100%',
      height: '38px',
      lineHeight: '38px',
      fontSize: '16px',
      border: '1px solid #0000001F',
      textAlign: 'center',
      caretColor: 'transparent',
      backgroundColor: '#e9ecef',
    },
    tab: {
      padding: '8px',
      width: '80px',
      lineHeight: '16px',
      textAlign: 'center',
      fontSize: '14px',
      cursor: 'pointer',
    },
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        settoggle(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('scroll', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (subtoggle === 'year') {
      setpickerValue(selectedYear);
    } else {
      setpickerValue(
        selectedMonthYear[0].month +
          '-' +
          selectedMonthYear[0].year +
          ' to ' +
          selectedMonthYear[1].month +
          '-' +
          selectedMonthYear[1].year
      );
    }
  }, [selectedYear, selectedMonthYear, subtoggle]);
  const newPickerValue =
    selectedMonthYear[0].month === selectedMonthYear[1].month &&
    subtoggle !== 'year'
      ? selectedMonthYear[0].month + '-' + selectedMonthYear[0].year
      : pickerValue;
  return (
    <div ref={pickerRef}>
      <input
        disabled={disabled}
        ref={pickerInputRef}
        type="text"
        style={disabled ? styles.inputDisabled : styles.input}
        onClick={() => {
          settoggle(true);
        }}
        value={newPickerValue}
      />
      {toggle && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            width: pickerInputRef.current.clientWidth,
            boxShadow: '0px 8px 16px 0px #00000030',
            zIndex: '99',
            backgroundColor: 'white',
            alignItems: 'center',
            padding: '4px',
          }}>
          <div style={{ display: 'flex', padding: '4px' }}>
            <div
              onClick={() => setsubtoggle('year')}
              style={{
                ...styles.tab,
                backgroundColor: subtoggle === 'year' ? '#DDEDFD' : '#EAEAEA',
                border: subtoggle === 'year' ? '1px solid #2E92FA' : 'none',
                color: subtoggle === 'year' ? '#0C74DA' : '#464646',
              }}>
              Year
            </div>
            <div
              onClick={() => setsubtoggle('month')}
              style={{
                ...styles.tab,
                backgroundColor: subtoggle === 'month' ? '#DDEDFD' : '#EAEAEA',
                border: subtoggle === 'month' ? '1px solid #2E92FA' : 'none',
                color: subtoggle === 'month' ? '#0C74DA' : '#464646',
              }}>
              Month
            </div>
          </div>
          <div>
            {subtoggle === 'year' ? (
              <div>
                <YearPicker
                  settoggle={settoggle}
                  selectedYear={selectedYear}
                  setselectedYear={setselectedYear}
                />
              </div>
            ) : (
              <div>
                <MonthPicker
                  settoggle={settoggle}
                  setselectedMonthYear={setselectedMonthYear}
                  selectedMonthYear={selectedMonthYear}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomPicker;
