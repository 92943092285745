import { scaleLinear } from 'd3-scale';
import Highcharts from 'highcharts';
import Moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import HighchartsReact from 'highcharts-react-official';

const dateFormat = /^\d{4}-\d{2}-\d{2}$/;

const calculateColorShade = (value, threshold, info) => {
  const colorShade = scaleLinear()
    .domain([0, threshold])
    .range([info.sc || 'white', info.mc || 'black']);
  return colorShade(value);
};

const addColorCode = dataset => {
  return (
    dataset &&
    dataset.map(data => {
      const info = data.info;
      return {
        ...data,
        uh: data.uh.map(item => ({
          ...item,
          data: item.data.map(point => {
            const value = parseFloat(point[2]);
            const threshold = parseFloat(item.thresholdPercentage);
            const color =
              value > threshold
                ? info.ec === null
                  ? '#FFA200'
                  : info.ec
                : value === 0
                  ? info.sc === null
                    ? 'white'
                    : info.sc
                  : calculateColorShade(value, threshold, info);
            return [...point, color];
          }),
        })),
      };
    })
  );
};

const Heatmap = ({ data, dateSelection, onBlockClick, width }) => {
  if (data && data.length === 0)
    return (
      <Row>
        <Col md={12} className="addMarginTop nodatafound">
          <div> Sorry...</div>
          <div className="addMarginTop">No data found !</div>
        </Col>
      </Row>
    );
  const refactoredDataSet = addColorCode(data);
  const info = refactoredDataSet[0].info;

  const yAxisCategories = refactoredDataSet[0].yaxis.map(y =>
    dateFormat.test(y) ? Moment(y).format('DD-MM-YYYY') : y
  );

  const options = {
    lang: {
      infoButtonKey: 'Usage Histogram heatmap',
    },
    chart: {
      type: 'heatmap',
      marginLeft: dateSelection === 'Year' ? 115 : 110,
      spacing: [0, 40, 0, 0],
      height:
        refactoredDataSet[0].yaxis.length *
        (dateSelection === 'Weekly'
          ? 35
          : dateSelection === 'Year'
            ? 30
            : dateSelection === 'Custom'
              ? 30
              : 25),
      width,
      plotBorderColor: 'black',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: [
      {
        categories: refactoredDataSet[0].xaxis,
        startOnTick: true,
        tickmarkPlacement: 'on',
        title: {
          text: refactoredDataSet[0].info.xt,
          align: 'low',
          x: 0,
          style: {
            fonFamily: 'lightFont,Arial,Helvetica,sans-serif !important',
            fontSize: '14px !important',
          },
        },
        labels: {
          align: 'left',
        },
        lineColor: '#ccd6eb',
        lineWidth: 1,
        opposite: false,
        tickWidth: 1.5,
        tickLength: 10,
        tickColor: '#ccd6eb',
        offset: 0,
      },
    ],
    yAxis: {
      categories: yAxisCategories,
      reversed: true,
      // startOnTick: true,
      tickmarkPlacement: 'on',
      // offset: 0,
      opposite: false,
      tickWidth: 1.5,
      tickLength: 7,
      // tickmarkPlacement: "right",
      tickColor: '#ccd6eb',

      title: {
        text: `${refactoredDataSet[0].info.yt}`,
        align: 'high',
        y: 0,
        offset: 90,
        style: {
          fonFamily: 'lightFont,Arial,Helvetica,sans-serif !important',
          fontSize: '14px !important',
        },
      },
      min: 0,
      lineColor: '#ccd6eb',
      lineWidth: 1,
    },
    tooltip: {
      enabled: true,
      formatter: function () {
        const value = this.point.value;
        if (value === 0) {
          return false;
        }
        return `<span style="color:${this.point.color}">\u258D</span><strong>${
          refactoredDataSet[0].info.xt
        }:</strong> ${this.series.xAxis.categories[this.point.x]} to ${
          this.series.xAxis.categories[this.point.x + 1]
        } <br>
                <strong>${refactoredDataSet[0].info.yt}:</strong> ${
                  this.series.yAxis.categories[this.point.y]
                }<br><strong>Budget Allocated:</strong> ${this.point.thresholdValue.toFixed(
                  2
                )}
        <br><strong>Budget Used:</strong> ${this.point.percentage.toFixed(
          2
        )} (${refactoredDataSet[0].info.ut})<br><strong>Percentage:</strong> ${
          value.toFixed(2) + '%'
        }`;
      },
    },

    plotOptions: {
      series: {
        pointPadding: 0.8,
        borderColor: '#fff',
        borderWidth: 0,
        point: {
          events: {
            click: event => onBlockClick(event),
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        pointPlacement: 'between',
        data: refactoredDataSet[0].uh.flatMap((item, xIndex) => {
          const thresholdValue = parseFloat(item.thresholdValue);
          return item.data.map((dataItem, yIndex) => ({
            x: xIndex,
            y: yIndex,
            value: parseFloat(dataItem[2]),
            percentage: parseFloat(dataItem[3]),
            color: dataItem[4] || info.sc,
            thresholdValue,
          }));
        }),
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
          _id: 'custom_export',
          symbol: ``,
          symbolX: 30,
          symbolY: 30,
          align: 'right',
          verticalAlign: 'top',
          height: 25,
          width: 34,
          symbolSize: 22,
          y: 55,
          x: 32,
          menuItems: [],
          theme: {
            states: {
              hover: {
                fill: 'transparent',
              },
              pressed: {
                fill: 'transparent',
              },
            },
          },
        },
      },
    },
  };

  return (
    <div data-intro="usage-normalize" className="container-fluid">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default Heatmap;
