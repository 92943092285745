import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { ArrowDownLine, CloseIcon } from '../../assets/images';
import SearchBox from '../../components/SearchBox';
import {
  setGlossaryStateClose,
  setGlossaryStateOpen,
  setGlossaryTag,
} from '../DashBoard/actions';
import { setGlossaryStateOpenSaga } from '../DashBoard/sagas';
import {
  glossaryStateSelector,
  glossaryTagSelector,
} from '../DashBoard/selectors';
import './styles.scss';
const description = require('./descriptionData.json');

class Glossary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      glossaryData: [],
      isVisible: true,
      searchQuery: '',
      originalData: [],
      tagVisible: true,
      tagDetail: [],
    };
    this.topRef = React.createRef();
    this.glossaryRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.tagDetails !== '') {
      this.loadTagDetails();
    }

    this.loadGlossaryContent();
    window.addEventListener('scroll', this.toggleBackToTopVisibility);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.redirectToDataFromLetter();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.toggleBackToTopVisibility);
  }

  loadTagDetails = () => {
    const tagData = require('./tagData.json');
    if (this.props.tagDetails !== '') {
      const tagInfo = tagData.filter(d =>
        d.tagName.includes(this.props.tagDetails)
      );
      this.setState({ tagDetail: tagInfo });
    }
  };

  toggleBackToTopVisibility = () => {
    const glossary = window.scrollY;
    if (glossary > 400) {
      this.setState({ isVisible: true });
    } else {
      this.setState({ isVisible: false });
    }
  };

  loadGlossaryContent = () => {
    // fetch(`${process.env.PUBLIC_URL}/glossary.txt`)
    //   .then(response => response.text())
    //   .then(text => {
    // const glossaryDataOld = this.parseGlossaryContent(text);
    // console.log('glossary data old', glossaryDataOld);
    // })
    // .catch(error => {
    //   console.error('error', error);
    // });
    const glossaryData = this.parseGlossaryContentNew(description);
    this.setState({ glossaryData }, this.redirectToDataFromLetter);
    this.setState({ originalData: glossaryData });
  };

  handleSearch = value => {
    const searchQuery = value.toLowerCase();
    this.setState({ searchQuery });
    if (searchQuery === '') {
      this.setState({ glossaryData: this.state.originalData });
    } else {
      const searchData = description.filter(item => {
        return (
          item.Name.toLowerCase().includes(searchQuery) ||
          item.Description.toLowerCase().includes(searchQuery)
        );
      });
      const filtered = this.parseGlossaryContentNew(searchData);
      this.setState({ glossaryData: filtered });
    }
  };

  parseGlossaryContent = text => {
    const glossaryDatas = {};
    const formattedDatas = text.split('\n');
    formattedDatas.forEach(data => {
      const [key, value] = data.split(':').map(item => item.trim());
      if (key && value) {
        const zeroPositionLetter = key.charAt(0).toUpperCase();
        if (!glossaryDatas[zeroPositionLetter]) {
          glossaryDatas[zeroPositionLetter] = [];
        }
        glossaryDatas[zeroPositionLetter].push({ key, value });
      }
    });

    const glossaryArray = Object.keys(glossaryDatas)
      .sort()
      .map(letter => ({
        letter,
        items: glossaryDatas[letter],
      }));

    return glossaryArray;
  };

  parseGlossaryContentNew = description => {
    const glossaryDatas = {};
    let filteredTagData;
    if (this.props.tagDetails !== '') {
      filteredTagData = description.filter(item => {
        return item.tagsContent.some(tag =>
          tag.includes(this.props.tagDetails)
        );
      });
    } else {
      filteredTagData = description;
    }
    const formattedDatas = filteredTagData;
    formattedDatas.forEach(data => {
      const key = data.Name;
      const value = data.Description;
      const tags = data.tagsContent;
      const firstChar = data.firstChar;
      if (key && value) {
        const zeroPositionLetter = key.charAt(0).toUpperCase();
        if (!glossaryDatas[zeroPositionLetter]) {
          glossaryDatas[zeroPositionLetter] = [];
        }
        glossaryDatas[zeroPositionLetter].push({ key, value, tags, firstChar });
      }
    });

    const glossaryArray = Object.keys(glossaryDatas)
      .sort()
      .map(letter => ({
        letter,
        items: glossaryDatas[letter],
      }));

    return glossaryArray;
  };

  scrollToTop = () => {
    if (this.topRef.current) {
      this.topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  scrollToLetter = letter => {
    const tElement = document.getElementById(letter);
    if (tElement) {
      tElement.scrollIntoView({ behavior: 'instant', block: 'end' });
    } else {
      console.error(`error`);
    }
  };

  redirectToDataFromLetter = () => {
    const { letter } = this.props.match.params;
    if (letter) {
      this.scrollToLetter(letter.toUpperCase());
    }
  };

  toggleTagDetail = () => {
    const tagVisible = false;
    this.setState({ tagVisible });
    this.props.setGlossaryTag('');
  };

  closeGlossary = () => {
    this.props.setGlossaryStateOpen(false);
    this.props.toggleGlossary();
  };

  showCharacters = letter => {
    const charactersIn = [];
    this.state.glossaryData.map(letter => charactersIn.push(letter.letter));
    if (charactersIn.includes(letter)) {
      return (
        <span
          key={letter}
          className="alphabet-letter"
          onClick={() => this.scrollToLetter(letter)}>
          {letter}
        </span>
      );
    } else {
      return (
        <span key={letter} className="alphabet-letter-not">
          {letter}
        </span>
      );
    }
  };

  render() {
    const { glossaryData, isVisible } = this.state;

    return (
      <>
        {/* <Breadcrumb MonitoredCount={this.props.monitoredCountData} /> */}

        <div className="glossaryOverlay">
          <div className="glossaryContainer" ref={this.glossaryRef}>
            <div ref={this.topRef}></div>
            <div className="fixedHeader">
              <span className="redUnderline"></span>

              <div className="glossaryHeader">
                <p className="glossaryHeading">Glossary of Technical Terms</p>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <button
                    style={{ backgroundColor: 'white' }}
                    onClick={() => this.closeGlossary()}>
                    <CloseIcon />
                  </button>
                  <span style={{ height: '32px' }}></span>
                </span>
              </div>
              <div>
                <Row className="searchBox">
                  <SearchBox
                    query={this.state.searchQuery}
                    handleSearchInputChange={this.handleSearch}
                    enableSearch={false}
                    width={12}
                  />
                </Row>
              </div>
              <div className="alphabet-navigation">
                {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
                  .split('')
                  .map(letter => this.showCharacters(letter))}
              </div>
            </div>
            <div className="glossaryContent"></div>

            {this.state.tagVisible && this.state.tagDetail.length > 0 && (
              <Col md={11} className="tagContainer">
                <div>
                  <span
                    style={{
                      display: 'flex',
                      float: 'right',
                    }}>
                    <span style={{ height: '32px' }}></span>
                  </span>
                  <div className="tagHeader">
                    {this.state.tagDetail[0].tagName}
                  </div>
                  <div className="tagContent">
                    {this.state.tagDetail[0].Description}
                  </div>
                </div>
              </Col>
            )}
            <div className="glossary-items">
              {glossaryData.length > 0 &&
                glossaryData.map(group => (
                  <div
                    key={group.letter}
                    id={group.letter}
                    className="glossary-section">
                    <span className="redUnderlineSmall"></span>

                    <h2>{group.letter}</h2>
                    {group.items.map((item, index) => (
                      <p key={index}>
                        <strong>{item.key}:</strong> {item.value}
                      </p>
                    ))}
                    {/* <button
                      className="back-to-top-button"
                      onClick={this.scrollToTop}>
                      Back to the top
                    </button> */}
                  </div>
                ))}
              {glossaryData.length === 0 && (
                <span className="glossaryNoData">No Results Found</span>
              )}
            </div>
          </div>
        </div>
        <div className="backtotopdiv">
          {isVisible && (
            <span
              onClick={this.scrollToTop}
              className="backToTop"
              style={{
                bottom: isVisible ? '20px' : '-100px',
                opacity: isVisible ? 1 : 0,
                transition: 'bottom 0.5s ease-in-out, opacity 0.5s ease-in-out',
              }}>
              <ArrowDownLine style={{ transform: 'rotate(180deg)' }} />
            </span>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = createStructuredSelector({
  glossaryState: glossaryStateSelector(),
  glossaryTag: glossaryTagSelector(),
});
const mapDispatchToProps = {
  setGlossaryStateOpen,
  setGlossaryStateClose,
  setGlossaryStateOpenSaga,
  setGlossaryTag,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Glossary)
);
