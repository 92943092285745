import 'bootstrap-daterangepicker/daterangepicker.css';
import Moment from 'moment';
import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DropdownList } from 'react-widgets';
import {
  CaretDown,
  Edit,
  Error,
  Reseteventfilter,
  Rightpointarrow,
  Warning,
} from '../../assets/images';
import Loading from '../../components/Loading';
import {
  setGlossaryStateOpen,
  setGlossaryTag,
} from '../../containers/DashBoard/actions';
import { checkUserEntitlements } from '../../userConfig';
import { platformVersion } from '../../utils/excel-utils';
import CustomdropdownList from '../CustomDropdownList';
import EventPopup from '../EventPopup';
import Table from '../EventTable';
import EventUndoPopup from '../EventUndoPopup';
import { IconInfo } from '../Icons';
import './styles.scss';
class EventList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messageInputValue: '',
      pecInputValue: '',
      loadingEventList: this.props.loadingEventList,
      modalShow: false,
      undoModalShow: false,
      clickedObj: '',
      showMore: false,
      expandOverAll:
        localStorage.getItem('toggleEFilterInfo') !== null
          ? localStorage.getItem('toggleEFilterInfo')
          : true,
      vehicleList: [],
      productList: [],
      essSnList: [],
      selectedVehicle: 'All vehicles',
      selectedProduct: 'All product',
      filterStartDate: new Date(Moment().subtract(1, 'days').startOf('day')),
      filterEndDate: new Date(Moment().subtract(1, 'days').endOf('day')),
      dateType:
        new URL(window.location.href).searchParams.get('efdt') !== null
          ? new URL(window.location.href).searchParams.get('efdt')
          : 'Any time',
      eventType: { info: false, warning: false, error: false },
      searchColumn: 'Select a column',
      searchColumnValue: '',
      strReasonArr: ['C-Rate', 'Cycles', 'Temperature', 'SOC'],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.vehiclesListData !== this.props.vehiclesListData) {
      this.setFilterValues();
    }
    const searchParams = new URLSearchParams(window.location.search);
    const prevSearchParams = new URLSearchParams(prevProps.location.search);
    if (prevSearchParams.get('ft') !== searchParams.get('ft')) {
      this.setState({ messageInputValue: '', pecInputValue: '' });
    }
  }

  openGlossary = () => {
    this.props.setGlossaryStateOpen(true);
    this.props.setGlossaryTag('Events view');
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.vehiclesListData !== newProps.vehiclesListData) {
      this.setState({ modalShow: false, undoModalShow: false });
      if (this.props.selectedView === 'Fleet') {
        this.setState({
          selectedVehicle: 'All vehicles',
          selectedProduct: 'All product',
          filterStartDate: new Date(
            Moment().subtract(1, 'days').startOf('day')
          ),
          filterEndDate: new Date(Moment().subtract(1, 'days').endOf('day')),
          dateType: 'Any time',
          eventType: { info: false, warning: false, error: false },
          searchColumn: 'Select a column',
          searchColumnValue: '',
        });
      }
    }
    if (this.props.selectedVehicle !== newProps.selectedVehicle) {
      if (this.props.selectedView === 'Vehicle') {
        this.setState({
          selectedProduct: 'All product',
          filterStartDate: new Date(
            Moment().subtract(1, 'days').startOf('day')
          ),
          filterEndDate: new Date(Moment().subtract(1, 'days').endOf('day')),
          dateType: 'Any time',
          eventType: { info: false, warning: false, error: false },
          searchColumn: 'Select a column',
          searchColumnValue: '',
        });
      }
    }
    if (this.props.selectedProduct !== newProps.selectedProduct) {
      if (this.props.selectedView === 'VehicleProduct') {
        this.setState({
          filterStartDate: new Date(
            Moment().subtract(1, 'days').startOf('day')
          ),
          filterEndDate: new Date(Moment().subtract(1, 'days').endOf('day')),
          dateType: 'Any time',
          eventType: { info: false, warning: false, error: false },
          searchColumn: 'Select a column',
          searchColumnValue: '',
        });
      }
    }

    if (new URL(window.location.href).searchParams.get('efty') !== null) {
      if (new URL(window.location.href).searchParams.get('efty') === 'Error') {
        this.setState(prevState => ({
          eventType: {
            info: false,
            error: true,
            warning: false,
          },
        }));
      }
      if (
        new URL(window.location.href).searchParams.get('efty') === 'Warning'
      ) {
        this.setState(prevState => ({
          eventType: {
            info: false,
            error: false,
            warning: true,
          },
        }));
      }
      if (
        new URL(window.location.href).searchParams.get('efty') === 'Information'
      ) {
        this.setState(prevState => ({
          eventType: {
            info: true,
            error: false,
            warning: false,
          },
        }));
      }
    }
    if (new URL(window.location.href).searchParams.get('efdt') !== null) {
      this.setState(prevState => ({
        dateType: new URL(window.location.href).searchParams.get('efdt'),
      }));
    }
  }

  setEventFilter = (key, value) => {
    if (key === 'startdate') {
      this.setState({ filterStartDate: value });
    }
    if (key === 'enddate') {
      this.setState({ filterEndDate: value });
    }
    if (key === 'calander') {
      this.setState({ dateType: value });
    }
    if (key === 'eventtype') {
      if (value === 'info') {
        this.setState(prevState => ({
          eventType: {
            ...prevState.eventType,
            info: !this.state.eventType.info,
          },
        }));
      } else if (value === 'warning') {
        this.setState(prevState => ({
          eventType: {
            ...prevState.eventType,
            warning: !this.state.eventType.warning,
          },
        }));
      } else if (value === 'error') {
        this.setState(prevState => ({
          eventType: {
            ...prevState.eventType,
            error: !this.state.eventType.error,
          },
        }));
      }
    }
    if (key === 'vehicle') {
      this.setState({ selectedVehicle: value });
      this.setState({ selectedProduct: 'All product' });
    }
    if (key === 'product') {
      this.setState({ selectedProduct: value });
    }
    if (key === 'searchColumn') {
      this.setState({ searchColumn: value });
    }
    if (key === 'searchColumnValue') {
      this.setState({ searchColumnValue: value.target.value });
    }
  };

  setFilterValues = () => {
    if (this.props.vehiclesListData !== null) {
      if (this.props.selectedView === 'Fleet') {
        const vehicleList = ['All vehicles'];
        const productList = ['All product'];
        const essSnList = ['All'];

        this.props.vehiclesListData[0].vst.forEach(vehicle => {
          let tempVehicle = vehicle.vn.split('_');
          tempVehicle = tempVehicle[tempVehicle.length - 1];
          vehicleList.push(tempVehicle);
          if (
            this.state.selectedVehicle === 'All vehicles' ||
            this.state.selectedVehicle === tempVehicle
          ) {
            vehicle.vdat.forEach(product => {
              const tempProduct = product.dn.split('_');
              let result = '';
              tempProduct.forEach((value, index) => {
                if (tempProduct.length - 1 !== index)
                  result +=
                    value + (tempProduct.length - 2 !== index ? '_' : '');
              });
              productList.push(result);
              essSnList.push(tempProduct[tempProduct.length - 1]);
            });
          }
        });

        this.setState({
          vehicleList: [...new Set(vehicleList)],
          productList: [...new Set(productList)],
          essSnList: [...new Set(essSnList)],
        });
      } else if (this.props.selectedView === 'Vehicle') {
        this.setState({
          selectedVehicle: sessionStorage.getItem('selectedVehicle'),
        });

        let tempVehicle = sessionStorage.getItem('selectedVehicle');
        tempVehicle = tempVehicle.split('_');
        tempVehicle = tempVehicle[tempVehicle.length - 1];
        const vehicleList = [tempVehicle];
        const productList = ['All product'];
        const essSnList = ['All'];

        this.props.vehiclesListData[0].vst.forEach(vehicle => {
          vehicle.vn === sessionStorage.getItem('selectedVehicle') &&
            vehicle.vdat.forEach(product => {
              const tempProduct = product.dn.split('_');
              let result = '';
              tempProduct.forEach((value, index) => {
                if (tempProduct.length - 1 !== index)
                  result +=
                    value + (tempProduct.length - 2 !== index ? '_' : '');
              });
              productList.push(result);
              essSnList.push(tempProduct[tempProduct.length - 1]);
            });
        });
        this.setState({
          vehicleList: [...new Set(vehicleList)],
          productList: [...new Set(productList)],
          essSnList: [...new Set(essSnList)],
        });
      } else if (this.props.selectedView === 'VehicleProduct') {
        this.setState({
          selectedVehicle: sessionStorage.getItem('selectedVehicle'),
          selectedProduct: sessionStorage.getItem('selectedProductDname'),
        });
        let tempVehicle = sessionStorage.getItem('selectedVehicle');
        tempVehicle = tempVehicle.split('_');
        tempVehicle = tempVehicle[tempVehicle.length - 1];
        this.setState({
          vehicleList: [tempVehicle],
        });

        let tempProduct = sessionStorage.getItem('selectedProductDname');
        let result = '';
        tempProduct = tempProduct.split('_');
        tempProduct.forEach((value, index) => {
          if (tempProduct.length - 1 !== index)
            result += value + (tempProduct.length - 2 !== index ? '_' : '');
        });
        this.setState({
          productList: [result],
          essSnList: [tempProduct[tempProduct.length - 1]],
        });
      }
    }
  };

  setShowMore = flag => {
    this.setState({ showMore: flag });
  };

  setModalShow = obj => {
    this.props.handleClose();
    this.setState(
      prevState => ({
        modalShow: !prevState.modalShow,
      }),
      () => {
        if (typeof obj === 'object' && obj !== null) {
          this.props.setEventNewPec(obj);
          this.props.setEventUpdateDate(Moment().format('DD:MM:YYYY HH:mm:ss'));
        }
      }
    );
  };

  checkDateValidation = () => {
    // check the dates
    const totalDays =
      Math.floor(
        Moment.duration(
          Moment(this.state.filterEndDate).diff(
            Moment(this.state.filterStartDate)
          )
        ).asHours() / 24
      ) + 1;

    if (totalDays > 30) {
      return false;
    } else if (totalDays < 1) {
      return false;
    } else {
      return true;
    }
  };

  undoConfirm = obj => {
    this.setState(prevState => ({
      undoModalShow: !prevState.undoModalShow,
      clickedObj: obj,
    }));
  };

  typeFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <div>
        <button
          title="Error"
          type="button"
          className={
            this.state.eventType.error === true
              ? 'btn  btn-sm typefiltertogglebutton activetypefilter'
              : 'btn  btn-sm typefiltertogglebutton'
          }
          onClick={e => {
            if (this.state.eventType.error === false) {
              setFilter('Error' || undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('efty', 'Error');
                window.history.pushState(null, null, url);
              }
            } else {
              setFilter(undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('efty');
                window.history.pushState(null, null, url);
              }
            }
            this.setState(prevState => ({
              eventType: {
                error: !this.state.eventType.error,
                warning: false,
                info: false,
              },
            }));
          }}>
          <Error />
        </button>
        <button
          title="Warning"
          type="button"
          className={
            this.state.eventType.warning === true
              ? 'btn  btn-sm typefiltertogglebutton activetypefilter'
              : 'btn  btn-sm typefiltertogglebutton'
          }
          onClick={e => {
            if (this.state.eventType.warning === false) {
              setFilter('Warning' || undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('efty', 'Warning');
                window.history.pushState(null, null, url);
              }
            } else {
              setFilter(undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('efty');
                window.history.pushState(null, null, url);
              }
            }
            this.setState(prevState => ({
              eventType: {
                warning: !this.state.eventType.warning,
                error: false,
                info: false,
              },
            }));
          }}>
          <Warning />
        </button>
        <button
          title="Information"
          type="button"
          className={
            this.state.eventType.info === true
              ? 'btn  btn-sm typefiltertogglebutton activetypefilter'
              : 'btn  btn-sm typefiltertogglebutton'
          }
          onClick={e => {
            if (this.state.eventType.info === false) {
              setFilter('Information' || undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('efty', 'Information');
                window.history.pushState(null, null, url);
              }
            } else {
              setFilter(undefined);
              const url = new URL(window.location.href);
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.delete('efty');
                window.history.pushState(null, null, url);
              }
            }
            this.setState(prevState => ({
              eventType: {
                info: !this.state.eventType.info,
                error: false,
                warning: false,
              },
            }));
          }}>
          <img src={require('../../assets/images/info.svg').default} />
        </button>
      </div>
    );
  };

  reasonFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <DropdownList
        data={[
          'All',
          'C-Rate',
          'Cycles',
          'Temperature',
          'Firmware Update',
          'Software Update',
          'Product counter calibration',
          'Update Information',
          'Change Request',
          'SOC',
          'File Count',
        ]}
        defaultValue={'All'}
        value={filterValue}
        className="sidemenufilterdropdown rw_reason_input"
        onChange={e => {
          if (e === 'All') {
            setFilter(undefined);
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.delete('efr');
              window.history.pushState(null, null, url);
            }
          } else {
            setFilter(e || undefined);
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.set('efr', e);
              window.history.pushState(null, null, url);
            }
          }
        }}
        selectIcon={<CaretDown />}
      />
    );
  };

  messageFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <DebounceInput
        minLength={3}
        placeholder={`Keywords`}
        debounceTimeout={1000}
        className="searchkeyword"
        onChange={e => {
          this.setState({ messageInputValue: e.target.value });
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            url.searchParams.set('efm', e.target.value.trim());
            window.history.pushState(null, null, url);
          }
        }}
        value={filterValue || ''}
      />
    );
  };

  dateFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <>
        <CustomdropdownList
          option={[
            'Any time',
            'Past 48 hours',
            'Past 7 days',
            'Past 30 days',
            'Past year',
            'Custom range',
          ]}
          defaultValue={this.state.dateType}
          value={this.state.dateType}
          className="sidemenufilterdropdown"
          onChange={e => {
            if (e === 'Custom range') {
              document.getElementById('datePickerClick').click();
            } else {
              const url = new URL(window.location.href);

              this.setState({ dateType: e });
              this.props.fetchAllEventList(
                url.searchParams.get('ft'),
                url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
                url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
                url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
                Moment(this.state.filterStartDate)
                  .format('YYYY-MM-DDTHH:mm:ss')
                  .replace(/:/g, '%3A'),
                Moment(this.state.filterEndDate)
                  .format('YYYY-MM-DDTHH:mm:ss')
                  .replace(/:/g, '%3A'),
                e || 'Any time',
                this.props.eventData.uniqueKey || 'All',
                this.props.eventData.uniqueValue || 'All',
                this.props.eventData.pageSize,
                0,
                !this.props.eventData.sortValue &&
                  this.props.eventData.sortValueKey
                  ? 1
                  : 0,
                this.props.eventData.sortValueKey
                  ? this.props.eventData.sortValueKey
                  : 'dt'
              );
              if (window.location.href.indexOf('?') > -1) {
                url.searchParams.set('efdt', e);
                url.searchParams.delete('efsd');
                url.searchParams.delete('efed');
                window.history.pushState(null, null, url);
              }
            }
          }}
          selectIcon={<CaretDown />}
        />
        <DateRangePicker
          initialSettings={{
            startDate: Moment(this.state.filterStartDate).format('MM/DD/YYYY'),
            endDate: Moment(this.state.filterEndDate).format('MM/DD/YYYY'),
            maxDate: Moment(
              new Date(Moment().subtract(1, 'days').endOf('day'))
            ),
          }}
          onCallback={this.handleDateRangeCallback}>
          <img
            src={require('../../assets/images/edit.svg').default}
            title="Review details"
            className={'daterangepickerimg'}
            id={'datePickerClick'}
          />
        </DateRangePicker>
      </>
    );
  };

  handleDateRangeCallback = (start, end, label) => {
    console.log('daterange:', end);
    this.setState({
      dateType: 'Custom range',
      filterStartDate: Moment(start)._d,
      filterEndDate: Moment(end)._d,
    });
    const url = new URL(window.location.href);
    if (window.location.href.indexOf('?') > -1) {
      url.searchParams.set('efdt', 'Custom range');
      url.searchParams.set('efsd', Moment(start).format('YYYY-MM-DDTHH:mm:ss'));
      url.searchParams.set('efed', Moment(end).format('YYYY-MM-DDTHH:mm:ss'));
      window.history.pushState(null, null, url);
    }

    this.props.fetchAllEventList(
      url.searchParams.get('ft'),
      url.searchParams.get('vc') ? url.searchParams.get('vc') : '',
      url.searchParams.get('vp') ? url.searchParams.get('vp') : '',
      url.searchParams.get('pk') ? url.searchParams.get('pk') : '',
      Moment(start).format('YYYY-MM-DDTHH:mm:ss'),
      Moment(end).format('YYYY-MM-DDTHH:mm:ss'),
      'Custom range',
      this.props.eventData.uniqueKey || 'All',
      this.props.eventData.uniqueValue || 'All',
      this.props.eventData.pageSize,
      this.props.eventData.pageIndex,
      !this.props.eventData.sortValue && this.props.eventData.sortValueKey
        ? 1
        : 0,
      this.props.eventData.sortValueKey
        ? this.props.eventData.sortValueKey
        : 'dt'
    );
  };

  vehicleFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    const url = new URL(window.location.href);
    const vehicle = url.searchParams.get('vc');
    const newVehicle = vehicle && vehicle.split('_');
    // const formattedVehicleLists = vehicle
    //   ? ["All vehicles", newVehicle.length > 0 && newVehicle[newVehicle.length - 1]]
    //   : this.state.vehicleList;
    return (
      <DropdownList
        data={this.state.vehicleList}
        defaultValue={
          newVehicle && newVehicle.length > 0
            ? newVehicle[newVehicle.length - 1]
            : 'All vehicles'
        }
        value={filterValue}
        // disabled={["Vehicle products", "Module"]}
        className="sidemenufilterdropdown"
        // name="Vehicles"
        onChange={e => {
          if (e === 'All vehicles') {
            setFilter('All');
            this.setState({ selectedVehicle: e });
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.delete('efvn');
              window.history.pushState(null, null, url);
            }
          } else {
            setFilter(e || undefined);
            this.setState({ selectedVehicle: e });
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.set('efvn', e);
              window.history.pushState(null, null, url);
            }
          }
        }}
        selectIcon={<CaretDown />}
      />
    );
  };

  productTypeFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    return (
      <DropdownList
        data={this.state.productList}
        defaultValue={'All product'}
        value={filterValue}
        // disabled={["Vehicle products", "Module"]}
        className="sidemenufilterdropdown"
        // name="Vehicles"
        onChange={e => {
          if (e === 'All product') {
            setFilter(undefined);
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.delete('efpn');
              window.history.pushState(null, null, url);
            }
          } else {
            setFilter(e || undefined);
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.set('efpn', e);
              window.history.pushState(null, null, url);
            }
          }
        }}
        selectIcon={<CaretDown />}
      />
    );
  };

  snFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    const url = new URL(window.location.href);
    const pdn = url.searchParams.get('pdn');
    const newPdn = pdn && pdn.split('_');
    const nm = newPdn && newPdn.length > 0 && newPdn[newPdn.length - 1];
    return (
      <DropdownList
        data={nm ? [nm] : this.state.essSnList}
        defaultValue={
          newPdn && newPdn.length > 0 ? newPdn[newPdn.length - 1] : 'All'
        }
        value={filterValue}
        // disabled={["Vehicle products", "Module"]}
        className="sidemenufilterdropdown"
        // name="Vehicles"
        onChange={e => {
          if (e === 'All') {
            setFilter(undefined);
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.delete('efsn');
              window.history.pushState(null, null, url);
            }
          } else {
            setFilter(e || undefined);
            const url = new URL(window.location.href);
            if (window.location.href.indexOf('?') > -1) {
              url.searchParams.set('efsn', e);
              window.history.pushState(null, null, url);
            }
          }
        }}
        selectIcon={<CaretDown />}
      />
    );
  };

  pecFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
    return (
      <DebounceInput
        minLength={3}
        debounceTimeout={1000}
        value={filterValue || ''}
        className="searchkeyword"
        onChange={e => {
          setFilter(e.target.value || undefined);
          const url = new URL(window.location.href);
          url.searchParams.set('efp', e.target.value);
          window.history.pushState(null, null, url);
        }}
        placeholder={`Keywords`}
      />
    );
  };

  productFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    const url = new URL(window.location.href);
    const pdn = url.searchParams.get('pdn');
    return (
      <DebounceInput
        minLength={3}
        debounceTimeout={1000}
        value={filterValue || pdn}
        className="searchkeyword"
        onChange={e => {
          setFilter(e.target.value || undefined);
          const url = new URL(window.location.href);
          url.searchParams.set('ebpft', e.target.value);
          window.history.pushState(null, null, url);
        }}
        placeholder={`Keywords`}
        disabled={this.props.selectedView !== 'Fleet' && pdn}
      />
    );
  };

  resetFilter = ({
    column: { filterValue, setAllFilters, preFilteredRows, id },
  }) => {
    return (
      <Button
        variant="link"
        className="btn btn-sm"
        onClick={() => {
          if (this.state.dateType !== 'Any time') {
            this.setState({ dateType: 'Any time' });
            this.props.eventFilterApply(
              this.state.filterStartDate,
              this.state.filterEndDate,
              'Any time',
              'eventType',
              'All vehicles',
              'All product',
              this.state.searchColumn,
              this.state.searchColumnValue
            );
          } else {
            document.getElementById('eventfilterresetClick').click();
            this.setState({
              eventType: {
                warning: false,
                error: false,
                info: false,
              },
            });
          }

          const url = new URL(window.location.href);
          if (window.location.href.indexOf('?') > -1) {
            url.searchParams.delete('efty');
            url.searchParams.delete('efr');
            url.searchParams.delete('efm');
            url.searchParams.delete('efdt');
            url.searchParams.delete('efsd');
            url.searchParams.delete('efed');
            url.searchParams.delete('efvn');
            url.searchParams.delete('efpn');
            url.searchParams.delete('efsn');
            url.searchParams.delete('ebpft');
            url.searchParams.delete('efp');
            window.history.pushState(null, null, url);
          }
        }}>
        <Reseteventfilter />
      </Button>
    );
  };

  componentDidMount() {
    this.setFilterValues();
    this.checkDateValidation();
  }

  render() {
    const columns = [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Header: '',
        Filter: '',
        filter: 'includes',
        Cell: ({ row, rows, page, toggleRowExpanded }) => (
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          <span
            {...row.getToggleRowExpandedProps({
              style: {
                // We can even use the row.depth property
                // and paddingLeft to indicate the depth
                // of the row
                paddingLeft: `${row.depth * 2}rem`,
              },
              title: 'Switch between show/hide detailed message',
              onClick: () => {
                const expandedRow = rows.find(row => row.isExpanded);
                if (expandedRow) {
                  const isSubItemOfRow = Boolean(
                    expandedRow && row.id.split('.')[0] === expandedRow.id
                  );

                  if (isSubItemOfRow) {
                    const expandedSubItem = expandedRow.subRows.find(
                      subRow => subRow.isExpanded
                    );

                    if (expandedSubItem) {
                      const isClickedOnExpandedSubItem =
                        expandedSubItem.id === row.id;
                      if (!isClickedOnExpandedSubItem) {
                        toggleRowExpanded(expandedSubItem.id, false);
                      }
                    }
                  } else {
                    toggleRowExpanded(expandedRow.id, false);
                  }
                }
                row.toggleRowExpanded();
              },
            })}>
            {row.isExpanded ? <CaretDown /> : <Rightpointarrow />}
          </span>
        ),
      },
      {
        id: 'typ',
        Header: 'TYPE',
        accessor: 'typ',
        Filter: this.typeFilter,
        // filter: "includes",
        Cell: props => {
          if (props.value === 'Information') {
            return (
              <img src={require('../../assets/images/info.svg').default} />
            );
          } else if (props.value === 'Warning') {
            return <Warning />;
          } else {
            return <Error />;
          }
        },
      },
      {
        id: 'rsn',
        Header: 'REASON',
        accessor: 'rsn',
        Filter: this.reasonFilter,
        // filter: "includes",
        Cell: cell => {
          if (
            this.state.strReasonArr.some(reason =>
              cell.row.values.rsn.includes(reason)
            )
          ) {
            if (cell.row.values.pn === null && platformVersion() === '3') {
              return cell.row.values.rsn;
            } else {
              return (
                <Button
                  variant="link"
                  className="btn btn-link btn-sm stress-link"
                  onClick={() =>
                    this.props.redirectToTimetdOnStreesEvtClk(cell.row.values)
                  }>
                  {cell.row.values.rsn}
                </Button>
              );
            }
          } else {
            return cell.row.values.rsn;
          }
        },
      },
      {
        id: 'ms',
        Header: 'MESSAGE',
        accessor: 'ms',
        Filter: this.messageFilter,
        Cell: props => <div style={{ marginLeft: '3%' }}>{props.value}</div>,
        // filter: "includes",
      },
      {
        id: 'dt',
        Header: 'DATE & TIME',
        accessor: 'dt',
        Filter: this.dateFilter,
        // filter: "includes",
        Cell: props => (
          <div style={{ marginLeft: '3%' }}>
            {Moment.utc(props.value).format('DD.MM.YYYY \xa0HH:mm:ss')}
          </div>
        ),
      },
      {
        id: 'vn',
        Header: 'VEHICLE NUMBER',
        accessor: 'vn',
        Filter: this.vehicleFilter,
        Cell: props => <div style={{ marginLeft: '3%' }}>{props.value}</div>,
        // filter: "equals",
      },
      {
        id: 'bp',
        Header: 'PRODUCT',
        accessor: 'bp',
        Filter: this.productFilter,
        Cell: props => <div style={{ marginLeft: '3%' }}>{props.value}</div>,
        // filter: "equals",
      },
      {
        id: 'esn',
        Header: 'ESS SN',
        accessor: 'esn',
        Filter: this.snFilter,
        Cell: props => <div style={{ marginLeft: '3%' }}>{props.value}</div>,
        // filter: "equals",
      },
      {
        id: 'pun',
        Header: 'PEC SN',
        accessor: 'pun',
        Filter: this.pecFilter,
        // filter: "includes",
        Cell: ({ cell }) => {
          if (cell.row.values.cd === '0X001') {
            return null;
          } else {
            let pecUniqueSerialNumberFormat = cell.row.values.pun;
            if (
              sessionStorage.getItem('selectedFleet') === 'ABB ESS Demo Fleet'
            ) {
              pecUniqueSerialNumberFormat =
                pecUniqueSerialNumberFormat.slice(32);
            } else {
              pecUniqueSerialNumberFormat =
                pecUniqueSerialNumberFormat.slice(-4);
            }
            return pecUniqueSerialNumberFormat;
          }
        },
      },
      {
        Header: 'ACTION',
        accessor: 'btn',
        Filter: this.resetFilter,
        // filter: "",
        Cell: ({ cell }) => {
          if (checkUserEntitlements('ESS:Events-Edit-Config-Changes')) {
            // <button value={cell.row.values.nm}>{cell.row.values.nm}</button>
            const ystrdy = Moment().subtract(1, 'days').endOf('day');
            const eventDate = cell.row.values.dt;
            const dateDiff = ystrdy.diff(eventDate, 'days');
            const isWithInsevenDays = !(dateDiff > 7);
            if (
              (cell.row.values.cd === '0X002' &&
                cell.row.values.sts === 'PENDING') ||
              (cell.row.values.cd === '0X002' &&
                cell.row.values.sts === 'Pending') ||
              (cell.row.values.cd === '0X005' &&
                cell.row.values.sts === 'PENDING') ||
              (cell.row.values.cd === '0X005' &&
                cell.row.values.sts === 'Pending')
            ) {
              return (
                <Button
                  variant="link"
                  className="btn btn-link btn-sm"
                  onClick={() => this.setModalShow(cell.row.values)}>
                  {/* {cell.row.values.nm}  Review details */}
                  <Edit />
                </Button>
              );
            } else if (
              (cell.row.values.cd === '0X002' &&
                cell.row.values.sts === 'OK' &&
                isWithInsevenDays === true) ||
              (cell.row.values.cd === '0X002' &&
                cell.row.values.sts === 'Ok' &&
                isWithInsevenDays === true) ||
              (cell.row.values.cd === '0X005' &&
                cell.row.values.sts === 'OK' &&
                isWithInsevenDays === true) ||
              (cell.row.values.cd === '0X005' &&
                cell.row.values.sts === 'Ok' &&
                isWithInsevenDays === true)
            ) {
              return (
                <Button
                  variant="link"
                  className="btn btn-link btn-sm undo-btn"
                  onClick={() => this.undoConfirm(cell.row.values)}>
                  {/* Undo changes */} <Reseteventfilter title="Undo changes" />
                </Button>
              );
            } else {
              return 'N/A';
            }
          } else {
            return 'N/A';
          }
        },
      },
      {
        Header: 'DETAILED MESSAGE',
        accessor: 'dms',
        Filter: '',
        // filter: "",
        // accessor: (data) => <Readmore data={data.dms} />,
      },
      {
        Header: 'ESS ARTICLE NUMBER',
        accessor: 'ean',
        Filter: '',
        // filter: "",
      },
      {
        Header: 'New PEC SERIAL NUMBER',
        accessor: 'npun',
        Filter: '',
        // filter: "",
      },
      {
        Header: 'PRODUCT TYPE',
        accessor: 'pt',
        Filter: '',
        // Cell: (props) => <div style={{ marginLeft: "3%" }}>{props.value}</div>,
        // filter: "equals",
      },
      {
        Header: 'CODE',
        accessor: 'cd',
        Filter: '',
        // filter: "",
      },
      {
        Header: 'PK',
        accessor: 'pk',
        Filter: '',
        // filter: "",
      },
      {
        Header: 'PN',
        accessor: 'pn',
        Filter: '',
        // filter: "",
      },
      {
        Header: 'STATUS',
        accessor: 'sts',
        Filter: '',
        // filter: "",
      },
      {
        Header: 'IP',
        accessor: 'ip',
        Filter: '',
        // filter: "",
      },
      {
        Header: 'AN',
        accessor: 'an',
        Filter: '',
        // filter: "",
      },
    ];

    return (
      <Row className="event-list-filter">
        {this.props.eventList === null ? (
          <Col>
            <Loading loadintText="Loading data" />
          </Col>
        ) : (
          <Col>
            <button
              data-intro="glossary-icon"
              title="Glossary Icon"
              className="infoIcon"
              onClick={this.openGlossary}>
              <IconInfo color={'black'} />
            </button>
            <Table
              columns={columns}
              data={this.props.eventList}
              style={{ maxWidth: window.innerWidth - 340 }}
              pageCount={
                this.props.eventListConfig && this.props.eventListConfig
              }
              fetchAllEventList={this.props.fetchAllEventList}
              setAllEventListData={this.props.setAllEventListData}
              eventData={this.props.eventData}
              loadingEventList={this.props.loadingEventList}
            />
            <EventPopup
              show={this.state.modalShow}
              onHide={() => this.setModalShow(false)}
              handleEventInputChange={this.props.handleEventInputChange}
              handleEventRadioChange={this.props.handleEventRadioChange}
              eventFormValues={this.props.eventFormValues}
              handleEventSubmit={this.props.handleEventSubmit}
              loadingSaveEvent={this.props.loadingSaveEvent}
              saveEventResponce={this.props.saveEventResponce}
            />
            <EventUndoPopup
              show={this.state.undoModalShow}
              onHide={() => this.undoConfirm(false)}
              handleUndoEventSubmit={this.props.handleUndoSubmit}
              loadingUndoEvent={this.props.loadingUndoEvent}
              undoEventResponce={this.props.undoEventResponce}
              clickedObj={this.state.clickedObj}
            />
          </Col>
        )}
      </Row>
    );
  }
}
const mapDispatchToProps = {
  setGlossaryStateOpen,
  setGlossaryTag,
};

export default withRouter(connect(null, mapDispatchToProps)(EventList));
