import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { withRouter } from 'react-router-dom';
import './Help.scss';

const Help = ({
  helpContent,
  placement,
  position,
  styleProps,
  history,
  char,
}) => {
  return (
    <OverlayTrigger
      delay={{ hide: 350, show: 300 }}
      overlay={props => (
        <Tooltip
          {...props}
          className={`tooltip-${'primary'}`}
          id={`tooltip-${'primary'}`}>
          {helpContent}
        </Tooltip>
      )}
      placement={placement}>
      <div className={`help`} style={{ position, ...styleProps }}>
        <div className="question">i</div>
      </div>
    </OverlayTrigger>
  );
};
export default withRouter(Help);
