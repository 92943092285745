export const SAVE_SITE_DATA = 'SAVE_SITE_DATA';
export const SAVE_SITE_DATA_SUCCESS = 'SAVE_SITE_DATA_SUCCESS';
export const SAVE_SITE_DATA_FAILURE = 'SAVE_SITE_DATA_FAILURE';

export const SAVE_FLEET_DATA = 'SAVE_FLEET_DATA';
export const SAVE_FLEET_DATA_SUCCESS = 'SAVE_FLEET_DATA_SUCCESS';
export const SAVE_FLEET_DATA_FAILURE = 'SAVE_FLEET_DATA_FAILURE';

export const CLEAR_SAVE_FLEET_RESPONCE = 'CLEAR_SAVE_FLEET_RESPONCE';
export const CLEAR_SAVE_SITE_RESPONCE = 'CLEAR_SAVE_SITE_RESPONCE';

export const GET_SETUP_SITE_LIST = 'GET_SETUP_SITE_LIST';
export const GET_SETUP_SITE_LIST_SUCCESS = 'GET_SETUP_SITE_LIST_SUCCESS';
export const GET_SETUP_SITE_LIST_FAILURE = 'GET_SETUP_SITE_LIST_FAILURE';

export const GET_SETUP_FLEET_LIST = 'GET_SETUP_FLEET_LIST';
export const GET_SETUP_FLEET_LIST_SUCCESS = 'GET_SETUP_FLEET_LIST_SUCCESS';
export const GET_SETUP_FLEET_LIST_FAILURE = 'GET_SETUP_FLEET_LIST_FAILURE';

// Vehicle setup
export const SAVE_VEHICLE_DATA = 'SAVE_VEHICLE_DATA';
export const SAVE_VEHICLE_DATA_SUCCESS = 'SAVE_VEHICLE_DATA_SUCCESS';
export const SAVE_VEHICLE_DATA_FAILURE = 'SAVE_VEHICLE_DATA_FAILURE';

export const GET_VEHICLESETUP_VALUES = 'GET_VEHICLESETUP_VALUES';
export const GET_VEHICLESETUP_VALUES_SUCCESS =
  'GET_VEHICLESETUP_VALUES_SUCCESS';
export const GET_VEHICLESETUP_VALUES_FAILURE =
  'GET_VEHICLESETUP_VALUES_FAILURE';

// product setup

export const SAVE_PRDT_DATA = 'SAVE_PRDT_DATA';
export const SAVE_PRDT_DATA_SUCCESS = 'SAVE_PRDT_DATA_SUCCESS';
export const SAVE_PRDT_DATA_FAILURE = 'SAVE_PRDT_DATA_FAILURE';

export const SAVE_PRDTINFO_DATA = 'SAVE_PRDTINFO_DATA';
export const SAVE_PRDTINFO_DATA_SUCCESS = 'SAVE_PRDTINFO_DATA_SUCCESS';
export const SAVE_PRDTINFO_DATA_FAILURE = 'SAVE_PRDTINFO_DATA_FAILURE';

export const GET_PRDT_SETUP_VALUES = 'GET_PRDT_SETUP_VALUES';
export const GET_PRDT_SETUP_VALUES_SUCCESS = 'GET_PRDT_SETUP_VALUES_SUCCESS';
export const GET_PRDT_SETUP_VALUES_FAILURE = 'GET_PRDT_SETUP_VALUES_FAILURE';

export const GET_PRDTINFO_SETUP_VALUES = 'GET_PRDTINFO_SETUP_VALUES';
export const GET_PRDTINFO_SETUP_VALUES_SUCCESS =
  'GET_PRDTINFO_SETUP_VALUES_SUCCESS';
export const GET_PRDTINFO_SETUP_VALUES_FAILURE =
  'GET_PRDTINFO_SETUP_VALUES_FAILURE';

// Side menu list
export const GET_SETUP_SIDEMENULIST = 'GET_SETUP_SIDEMENULIST';
export const GET_SETUP_SIDEMENULIST_SUCCESS = 'GET_SETUP_SIDEMENULIST_SUCCESS';
export const GET_SETUP_SIDEMENULIST_FAILURE = 'GET_SETUP_SIDEMENULIST_FAILURE';

export const GET_USED_FLEET_NAMELIST = 'GET_USED_FLEET_NAMELIST';
export const GET_USED_FLEET_NAMELIST_SUCCESS =
  'GET_USED_FLEET_NAMELIST_SUCCESS';
export const GET_USED_FLEET_NAMELIST_FAILURE =
  'GET_USED_FLEET_NAMELIST_FAILURE';

// Validate Fleet data path
export const GET_VALIDATE_FLEETDATAPATH = 'GET_VALIDATE_FLEETDATAPATH';
export const GET_VALIDATE_FLEETDATAPATH_SUCCESS =
  'GET_VALIDATE_FLEETDATAPATH_SUCCESS';
export const GET_VALIDATE_FLEETDATAPATH_FAILURE =
  'GET_VALIDATE_FLEETDATAPATH_FAILURE';

// Load Fleet data - Connectivity key path
export const GET_FLEETDATA = 'GET_FLEETDATA';
export const GET_FLEETDATA_SUCCESS = 'GET_FLEETDATA_SUCCESS';
export const GET_FLEETDATA_FAILURE = 'GET_FLEETDATA_FAILURE';
export const GET_FLEETUSER_DEV = [
  'ESSDevelopmentTeam',
  'ABB_ESS_Local_Admin',
  'ESS-Default-Group',
  'ESS-UI-DevelopmentTeam',
  'Traction_ESS_Test',
  'ESS-Tyne&wear',
  'ESS-Demo-Group',
  'Traction-ESS-IN',
  'ESS-QA-Team-India',
  'Traction_ESS_Test_AD',
];
export const GET_FLEETUSER_PROD = [
  'Traction_ESS_Internal',
  'Traction_ESS_Sales',
  'Traction_ESS_Leeuwarden',
  'Traction_ESS_Local_Admin',
  'Traction_ESS_Rendsburg',
  'Demo SmSe motor access',
  'Traction_ESS_Global_Admin',
  'UFIDrivesESS200',
];
