import React from 'react';
import { ToastBar, Toaster, toast } from 'react-hot-toast';
import { IconClose } from '../Icons';

/**
 * CustomToaster
 * @description Custom Toaster component on top of react-hot-toast
 * @see https://react-hot-toast.com/docs/toast
 * @returns {JSX.Element}
 */

function CustomToaster() {
  return (
    <Toaster
      position="top-right"
      limit={10}
      reverseOrder={false}
      containerStyle={{
        marginTop: '40px',
        fontWeight: 'bold',
      }}
      toastOptions={{
        success: {
          iconTheme: {
            primary: 'white',
            secondary: '#2A8B4B',
          },
          style: {
            borderRadius: '0px',
            background: '#2A8B4B',
            color: 'white',
            height: '48px',
          },
        },
        error: {
          iconTheme: {
            primary: 'white',
            secondary: '#F03040',
          },
          style: {
            borderRadius: '0px',
            background: '#F03040',
            color: 'white',
            height: '100px',
          },
          duration: Infinity,
        },
      }}>
      {t => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <button
                  style={{ background: 'none' }}
                  onClick={() => toast.dismiss(t.id)}>
                  <IconClose height="16" width="16" stroke="white" />
                </button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}

export default CustomToaster;
