import Moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  checkUserEntitlements,
  userConfig as configUser,
} from '../../userConfig';
import Loading from '../Loading';
import Overview from '../Overview';
import PieGraph from '../PieGraph';
import SingleGraph from '../SingleGraph';
import StockGraph from '../StockGraph';
import UtilizationThresholdInfo from '../UtilizationThresholdInfo';

export const EssPlatform1Preview = ({
  selectedFleet,
  selectedVehicle,
  selectedESS,
  startDate,
  endDate,
  vehicleCount,
  essCount,
  loadingVehicles,
  vehiclesListData,
  overviewData,
  onVehicleListClick,
  listSelection,
  filterItems,
  sideMenuFilter,
  filterAvailability,
  filterReliability,
  filterUtilization,
  batteryRelData,
  batteryStrData,
  operatingHoursHistogramData,
  temperatureHistogramData,
  usgSocHistogramData,
  usgPowHistogramData,
  usgDodHistogramData,
  list_temparture,
  list_crate,
  list_cycle,
  list_soc,
  list_filecount,
  selectedFleetType,
}) => {
  const userConfig = configUser();
  return (
    <>
      <Col className="containerreportpreviewtitlebar">
        <div className="reportview-menubar">
          <div className="reportview-title">Report preview</div>
        </div>
      </Col>
      <div className="reportpreview">
        <div className="generatedreportcontainer">
          <h6>
            <b className="reddash">____</b>
            <br />
            <b>ABB TRACTION SERVICE</b>
          </h6>
          <h4>
            <b>{selectedFleet} Traction Batteries</b>
          </h4>
          <h4>
            ESS Condition Monitoring ESS Report
            <br />
            {Moment(startDate).format('DD.MM.YYYY') +
              ' - ' +
              Moment(endDate).format('DD.MM.YYYY')}
          </h4>
          <div className="reportmainimg">
            {selectedFleetType === 'eBus' ? (
              <img
                src={require('../../assets/images/report/report_bus.jpg')}
                className="mainimg"
                id="fleet_image"
              />
            ) : (
              <img
                src={require('../../assets/images/report/report_main.jpg')}
                className="mainimg"
                id="fleet_image"
              />
            )}
          </div>
          <h4>
            <b>Contents</b>
          </h4>
          <h6>
            <ol type="1">
              <b>
                <li>Purpose and Basic Description</li>
                <li>Utilization ESS</li>
              </b>
              <ul type="square">
                <li>Fleet Level</li>
                {/* <ul type="disc">
                    <li>Overview KPIs </li>
                    <li>Data and Connectivity Health </li>
                  </ul> */}
                <li>ESS Level</li>
                <ul type="disc">
                  <li>Overview</li>
                  <li>{selectedESS}</li>
                </ul>
              </ul>
            </ol>
          </h6>
          <h4>
            <b>1. Purpose and Basic Description</b>
          </h4>
          <div className="description">
            This report compiles the performance data and analysis of the{' '}
            {selectedFleet} fleet. This report comprises following
            configuration:
            <ul type="disc">
              <li>{vehicleCount} Vehicles</li>
              <li>{essCount} Energy Storage Systems</li>
            </ul>
            The fleet’s performance assessment is deducted and evaluated from
            the data sources as illustrated in Figure 1:
            <div className="reportcondmonitorimg">
              <img
                src={require('../../assets/images/report/condmonitor.png')}
                className="condmonitorimg"
              />
            </div>
          </div>
          <h4>
            <b>2. Utilization ESS</b>
          </h4>
          <div className="description">
            This chapter displays the utilization of the traction batteries on a
            fleet level within the report period providing information on the
            usage and operational pattern of the systems through several KPIs
            subjected to history track and trend development.
          </div>
          <h5>
            <b>2.1. Fleet Level</b>
          </h5>
          <div className="description">
            The fleet counts on {vehicleCount} vehicles fitting {essCount}{' '}
            Energy Storage Systems (ESS).
          </div>
          <div className="reportview-fleet" id="overviewreport">
            <div className="producttitle">{'Overview'}</div>
            {checkUserEntitlements('ESS:Fleet-Overview') &&
              (loadingVehicles === true || vehiclesListData === null ? (
                <Loading loadintText="Loading data" />
              ) : vehiclesListData &&
                vehiclesListData[0].vst.length > 0 &&
                vehiclesListData &&
                vehiclesListData[0].data.length > 0 ? (
                <Overview
                  id="reportimg"
                  overviewData={overviewData}
                  onVehicleListClick={onVehicleListClick}
                  vehiclesListData={vehiclesListData}
                  listSelection={listSelection}
                  filterItems={filterItems}
                  userConfig={userConfig}
                  sideMenuFilter={sideMenuFilter}
                  filterAvailability={filterAvailability}
                  filterReliability={filterReliability}
                  filterUtilization={filterUtilization}
                />
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              ))}
          </div>
          {/* <h5>
              <b>2.1.1. Overview KPIs</b>
            </h5>
            <div className="description">
              Table 1 reflected in this chapter contains the key performance
              indicators applied to the fleet cumulated over the report period.
              <br />
              <br />
              Table 1: Global ESS Fleet KPIs
              <div style={{ "text-align": "-webkit-center" }}>
                <table className="fleetkpitable">
                  <tr>
                    <th style={{ width: "65%" }}>Measure</th>
                    <th style={{ width: "20%" }}>Value</th>
                    <th style={{ width: "20%" }}>Unit</th>
                  </tr>
                  <tr>
                    <td>
                      Number of operational ESS in fleet during reporting period
                    </td>
                    <td></td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Number of registered ESS in fleet</td>
                    <td></td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Nominal energy throughput per ESS</td>
                    <td></td>
                    <td>kWh/Day</td>
                  </tr>
                  <tr>
                    <td>Average fleet throughput during reporting period</td>
                    <td></td>
                    <td>kWh/Day</td>
                  </tr>
                  <tr>
                    <td>State of Health - ESS average</td>
                    <td></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>
                      State of Health – minimal
                      <br />
                      ESS and vehicle name
                    </td>
                    <td></td>
                    <td>%</td>
                  </tr>
                  <tr>
                    <td>
                      State of Health – maximal
                      <br />
                      ESS and vehicle name
                    </td>
                    <td></td>
                    <td>%</td>
                  </tr>
                </table>
              </div>
            </div> */}
          <h5>
            <b>2.2. ESS Level</b>
          </h5>
          <h6>
            <b>2.2.1. Overview</b>
          </h6>
          <div className="description">
            The following batteries in Table 2 correspond to the selected
            choice:
            <br />
            Table 2: overview of selected batteries
            <div style={{ 'text-align': '-webkit-center' }}>
              <table className="fleetkpitable">
                <tr>
                  <th>#</th>
                  <th style={{ width: '25%' }}>Battery</th>
                  <th style={{ width: '25%' }}>Vehicle</th>
                  <th style={{ width: '68%' }}>Description</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>{selectedESS}</td>
                  <td>{selectedVehicle}</td>
                  <td>-</td>
                </tr>
              </table>
            </div>
          </div>
          <h6>
            <b>2.2.2. {selectedESS}</b>
          </h6>
          <h6>
            <b>2.2.2.1. Reliability</b>
          </h6>

          <div
            className="reportview-productreliability"
            id="product_reliability">
            {/* {this.props.batteryRelData !== null && (
                <StockGraph
                  chartType="BATT_REL_COL_TYPE"
                  data={this.props.batteryRelData[0]}
                  //redirectToTimetrend={this.props.redirectToTimetrend}
                  report={true}
                />
              )} */}
            {batteryRelData.length > 0 ? (
              <Row>
                <Col md={12} className="noLeftpadding noRightpadding">
                  <div className="stsinfo">
                    <span className="txt">
                      {batteryRelData[0].sts === 'Reliable' ? (
                        <span id="ok"> Reliable </span>
                      ) : batteryRelData[0].sts === 'Not reliable' ? (
                        <span id="notok"> Not reliable </span>
                      ) : (
                        <span id="unknown"> Unknown </span>
                      )}
                    </span>
                    <span className="util-info-smalltxt">
                      Accumulated availability status over &nbsp;
                      {selectedFleet === 'ABB ESS Demo Fleet'
                        ? '30 days '
                        : 'the last 30 days '}{' '}
                      (Click on the section of stacked bar chart for details)
                    </span>
                    <br />
                    <span className="util-info-txt formulatext">
                      {batteryRelData[0].sts === 'Reliable' ? (
                        <span>
                          <span>Hours in error state</span>
                          <span className="dividesymbol"> &#247;</span>
                          <span> total operation time</span>{' '}
                          <span> &lt; {batteryRelData[0].ctv} %</span>
                        </span>
                      ) : batteryRelData[0].sts === 'Not reliable' ? (
                        <span>
                          <span>Hours in error state</span>
                          <span className="dividesymbol"> &#247;</span>
                          <span> total operation time</span>{' '}
                          <span> &gt;{batteryRelData[0].ctv} %</span>
                        </span>
                      ) : (
                        ''
                      )}
                    </span>{' '}
                  </div>
                </Col>
                <Col md={4} className="noLeftpadding noRightpadding">
                  <PieGraph
                    chartType="BATT_REL_PIE_TYPE"
                    data={batteryRelData[0].pie}
                    // pieChartSelected={this.pieChartSelected}
                  />
                </Col>
                <Col md={8} className="noLeftpadding noRightpadding">
                  {
                    <StockGraph
                      chartType="BATT_REL_COL_TYPE"
                      data={batteryRelData[0]}
                      // redirectToTimetrend={this.props.redirectToTimetrend}
                      report={true}
                    />
                  }
                </Col>
              </Row>
            ) : (
              <Col md={12} className="addMarginTop nodatafound">
                <div> Sorry...</div>
                <div className="addMarginTop">No data found !</div>
              </Col>
            )}
          </div>

          <h6>
            <b>2.2.2.2. Utilization</b>
          </h6>

          <div
            className="reportview-productreliability"
            id="product_utilization">
            {batteryStrData.length > 0 ? (
              <Row>
                <Col md={12} className="noLeftpadding noRightpadding">
                  <div className="stsinfo">
                    <span className="txt">
                      {batteryStrData[0].sts === 'Normal' ? (
                        <span id="ok"> Normal utilization </span>
                      ) : batteryStrData[0].sts === 'Above normal' ? (
                        <span id="notok"> Above normal utilization </span>
                      ) : (
                        <span id="unknown"> Unknown </span>
                      )}
                    </span>
                    <span className="util-info-smalltxt">
                      {selectedFleet === 'ABB ESS Demo Fleet'
                        ? ' 30 days '
                        : ' last 30 days '}
                      (Click on the section of stacked bar chart for details){' '}
                      <br />
                    </span>
                    {batteryStrData[0].sts === 'Normal' ? (
                      <Row className="util-info-txt formulatext">
                        <Col md={12} className="noLeftpadding ">
                          <span>Hours above normal utilization</span>
                          <span className="dividesymbol"> &#247;</span>
                          <span> total operation time</span>{' '}
                          <span>&lt; {batteryStrData[0].ctv.ctv} %</span>
                        </Col>
                        {/* <Col
                            md={3}
                            className="noLeftpadding noRightpadding d-flex align-items-center"
                          >
                            &lt; 25%
                          </Col> */}
                      </Row>
                    ) : batteryStrData[0].sts === 'Above normal' ? (
                      <Row className="util-info-txt formulatext">
                        <Col md={12} className="noLeftpadding ">
                          <span>Hours above normal utilization</span>
                          <span className="dividesymbol"> &#247;</span>
                          <span> total operation time</span>
                          <span> &gt; {batteryStrData[0].ctv.ctv} %</span>
                        </Col>
                        {/* <Col
                            md={3}
                            className="noLeftpadding noRightpadding d-flex align-items-center"
                          >
                            &gt; 25%
                          </Col> */}
                      </Row>
                    ) : (
                      <span className="util-info-txt">
                        {' '}
                        Utilization indicates unknown when the relavent data is
                        not available.
                        <br />{' '}
                      </span>
                    )}
                  </div>
                </Col>
                <Col md={4} className="noLeftpadding noRightpadding">
                  <PieGraph
                    chartType="BATT_STR_PIE_TYPE"
                    data={batteryStrData[0].pie}
                    // pieChartSelected={this.pieChartSelected}
                  />
                </Col>
                <Col md={8} className="noLeftpadding noRightpadding">
                  <StockGraph
                    chartType="BATT_STR_COL_TYPE"
                    data={batteryStrData[0]}
                    // redirectToTimetrend={this.props.redirectToTimetrend}
                    report={true}
                  />
                </Col>
                <div className="threstable">
                  {' '}
                  <UtilizationThresholdInfo
                    stressData={batteryStrData[0].ctv}
                  />
                </div>
              </Row>
            ) : (
              <Col md={12} className="addMarginTop nodatafound">
                <div> Sorry...</div>
                <div className="addMarginTop">No data found !</div>
              </Col>
            )}
          </div>
          <h6>
            <b>2.2.2.3. Operating hours</b>
          </h6>
          <div className="description">
            {/* <div className="producttitle">{"2.2.2.4.	Operating hours"}</div> */}
            <div className="reportview-product" id="operatinghoursreport">
              {operatingHoursHistogramData !== null &&
              operatingHoursHistogramData.rp.length > 0 ? (
                <SingleGraph
                  chartType="OPERATING_HOURS_HISTOGRAM_TYPE"
                  data={operatingHoursHistogramData.rp}
                  // activeTab={"usgpow"}
                  expandSide={true}
                  sideWidth={370}
                />
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>
          </div>
          <h6>
            <b>2.2.2.4. Temperature</b>
          </h6>
          <div className="description">
            {/* <div className="producttitle">{"Temperature"}</div> */}
            <div className="reportview-product" id="temperaturereport">
              {temperatureHistogramData !== null &&
              temperatureHistogramData.length > 0 ? (
                <SingleGraph
                  chartType="DESIGNED_TYPE"
                  data={temperatureHistogramData}
                  activeTab={'usgpow'}
                  expandSide={true}
                  sideWidth={370}
                />
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>
          </div>
          <h6>
            <b>2.2.2.5. State of Charge</b>
          </h6>
          <div className="description">
            <div className="reportview-product" id="socreport">
              {usgSocHistogramData !== null &&
              usgSocHistogramData.length > 0 ? (
                <SingleGraph
                  chartType="DESIGNED_TYPE"
                  data={usgSocHistogramData}
                  activeTab={'usgpow'}
                  expandSide={true}
                  sideWidth={370}
                />
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>
          </div>
          <h6>
            <b>2.2.2.6. Power</b>
          </h6>
          <div className="description">
            <div className="reportview-product" id="powerreport">
              {usgPowHistogramData !== null &&
              usgPowHistogramData.length > 0 ? (
                <SingleGraph
                  chartType="DESIGNED_TYPE"
                  data={usgPowHistogramData}
                  activeTab={'usgpow'}
                  expandSide={true}
                  sideWidth={370}
                />
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>
          </div>
          <h6>
            <b>2.2.2.7. Depth of discharge</b>
          </h6>
          <div className="description">
            <div className="reportview-product" id="dodreport">
              {usgDodHistogramData !== null &&
              usgDodHistogramData.length > 0 ? (
                <SingleGraph
                  chartType="DESIGNED_TYPE"
                  data={usgDodHistogramData}
                  activeTab={'usgpow'}
                  expandSide={true}
                  sideWidth={370}
                />
              ) : (
                <Col md={12} className="addMarginTop nodatafound">
                  <div> Sorry...</div>
                  <div className="addMarginTop">No data found !</div>
                </Col>
              )}
            </div>
          </div>
          <h6>
            <b>2.2.2.8. Event List</b>
          </h6>
          <div className="description">
            <div style={{ 'text-align': '-webkit-center' }} id="eventtable">
              <table className="fleetkpitable">
                <tr>
                  <th style={{ width: '15%' }}>Type</th>
                  <th style={{ width: '20%' }}>Reason</th>
                  <th style={{ width: '35%' }}>Message</th>
                  <th style={{ width: '30%', textAlign: 'center' }}>
                    # Events
                  </th>
                </tr>
                {list_temparture > 0 && (
                  <tr>
                    <td>Warning</td>
                    <td>Temperature</td>
                    <td>Above normal utilization detected</td>
                    <td style={{ textAlign: 'center' }}>{list_temparture}</td>
                  </tr>
                )}
                {list_crate > 0 && (
                  <tr>
                    <td>Warning</td>
                    <td>C-Rate</td>
                    <td>Above normal utilization detected</td>
                    <td style={{ textAlign: 'center' }}>{list_crate}</td>
                  </tr>
                )}
                {list_cycle > 0 && (
                  <tr>
                    <td>Warning</td>
                    <td>Cycles</td>
                    <td>Above normal utilization detected</td>
                    <td style={{ textAlign: 'center' }}>{list_cycle}</td>
                  </tr>
                )}
                {list_soc > 0 && (
                  <tr>
                    <td>Warning</td>
                    <td>SOC</td>
                    <td>Above normal utilization detected</td>
                    <td style={{ textAlign: 'center' }}>{list_soc}</td>
                  </tr>
                )}
                {list_filecount > 0 && (
                  <tr>
                    <td>Warning</td>
                    <td>File Count</td>
                    <td>Above normal utilization detected</td>
                    <td style={{ textAlign: 'center' }}>{list_filecount}</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
