import { fromJS } from 'immutable';
import Moment from 'moment';
import {
  CHECK_USER,
  CHECK_USER_FAILURE,
  CHECK_USER_SUCCESS,
  FETCH_ALL_FLEET_LIST,
  FETCH_ALL_FLEET_LIST_FAILURE,
  FETCH_ALL_FLEET_LIST_SUCCESS,
  FETCH_FILTER_LIST,
  FETCH_FILTER_LIST_FAILURE,
  FETCH_FILTER_LIST_SUCCESS,
  FETCH_FLEET_LIST,
  FETCH_FLEET_LIST_FAILURE,
  FETCH_FLEET_LIST_SUCCESS,
  FETCH_MAP_DATA,
  FETCH_MAP_DATA_FAILURE,
  FETCH_MAP_DATA_SUCCESS,
  FETCH_MONITORED_COUNT,
  FETCH_MONITORED_COUNT_FAILURE,
  FETCH_MONITORED_COUNT_SUCCESS,
  SET_CURRENT_FLEET,
  SET_DATE,
  SET_GLOSSARY_STATE_CLOSE,
  SET_GLOSSARY_STATE_OPEN,
  SET_GLOSSARY_TAG,
  SET_USER_GROUP,
} from './constants';

const initialState = fromJS({
  allFleetData: null,
  loading: null,
  loadingMap: false,
  loadingFilter: false,
  loadingMonitoredCount: false,
  loadingUserData: false,
  userData: null,
  startDate: new Date(Moment().subtract(15, 'days')),
  endDate: new Date(Moment().subtract(1, 'days')),
  selectedTrain: 'Re 460 117-5',
  mapData: null,
  fleetData: null,
  filterData: null,
  monitoredCountData: null,
  error: null,
  userGroup: null,
  glossaryState: false,
  glossaryTag: '',
});

export function reducerDashboard(state = initialState, action) {
  switch (action.type) {
    case FETCH_MAP_DATA:
      return state.set('loadingMap', true).set('error', null);
    case FETCH_MAP_DATA_SUCCESS:
      return state.set('loadingMap', false).set('mapData', action.Mapdata);
    case FETCH_MAP_DATA_FAILURE:
      return state
        .set('loadingMap', false)
        .set('mapData', null)
        .set('error', action.error);

    case FETCH_FLEET_LIST:
      return state.set('loading', true).set('error', null);
    case FETCH_FLEET_LIST_SUCCESS:
      return state.set('loading', false).set('fleetData', action.FleetList);
    case FETCH_FLEET_LIST_FAILURE:
      return state
        .set('loading', false)
        .set('fleetData', null)
        .set('error', action.error);

    case FETCH_ALL_FLEET_LIST:
      return state.set('loading', true).set('error', null);
    case FETCH_ALL_FLEET_LIST_SUCCESS:
      return state.set('loading', false).set('allFleetData', action.FleetList);
    case FETCH_ALL_FLEET_LIST_FAILURE:
      return state
        .set('loading', false)
        .set('allFleetData', null)
        .set('error', action.error);

    /// /////////////////////////////////////////////////////////////////////////////////////////
    case CHECK_USER:
      return state.set('loadingUserData', true).set('error', null);
    case CHECK_USER_SUCCESS:
      return state
        .set('loadingUserData', false)
        .set('userData', action.userData);
    case CHECK_USER_FAILURE:
      return state
        .set('loadingUserData', false)
        .set('userData', null)
        .set('error', action.error);

    /// /////////////////////////////////////////////////////////////////////////////////////////

    case FETCH_FILTER_LIST:
      return state.set('loadingFilter', true).set('error', null);
    case FETCH_FILTER_LIST_SUCCESS:
      return state
        .set('loadingFilter', false)
        .set('filterData', action.FilterList);
    case FETCH_FILTER_LIST_FAILURE:
      return state
        .set('loadingFilter', false)
        .set('filterData', null)
        .set('error', action.error);

    /// //////////////////////////////////////////////////////////////////
    case FETCH_MONITORED_COUNT:
      return state.set('loadingMonitoredCount', true).set('error', null);
    case FETCH_MONITORED_COUNT_SUCCESS:
      return state
        .set('loadingMonitoredCount', false)
        .set('monitoredCountData', action.MonitoredCount);
    case FETCH_MONITORED_COUNT_FAILURE:
      return state
        .set('loadingMonitoredCount', false)
        .set('monitoredCountData', null)
        .set('error', action.error);

    /// //////////////////////////////////////////

    case SET_CURRENT_FLEET:
      return state.set('selectedTrain', action.fleetName);

    case SET_USER_GROUP:
      return state.set('userGroup', action.userGroup);

    case SET_DATE:
      return state
        .set('startDate', action.payload.startDate)
        .set('endDate', action.payload.endDate);
    case SET_GLOSSARY_STATE_OPEN:
      return state.set('glossaryState', action.payload.isOpen);
    case SET_GLOSSARY_STATE_CLOSE:
      return state.set('glossaryState', false);
    case SET_GLOSSARY_TAG:
      return state.set('glossaryTag', action.payload.tagDetails);

    default:
      return state;
  }
}
